<template>
  <div ref="content">
    <loader v-if="isLoadings" />
    <div
      style="font-size: 30px;"
      v-else-if="for404"
    >
      404!
    </div>
    <div
      :class="{'button_fly': flyButton}"
      v-else-if="timeout"
    >
      <div
        class="flex align-center sulguni mb-7 rocky--text pointer"
        @click="linkClick()"
      >
        <r-icon
          class="mr-2 mt-2px"
          icon="arrow-left"
          fill="rocky"
          size="16"
        />
        Назад
      </div>
      <r-bulb
        :is-block="true"
        :is-shape="false"
        color="fargo"
        :title="!isSave && isEditPage ? 'Не сохранено' : ''"
        position="eluno"
        size="marina"
      >
        <template #content>
          <h1
            class="ricotta"
          >
            {{ isEditPage ? 'Редактирование уборки' : 'Новая уборка' }}
          </h1>
        </template>
      </r-bulb>
      <div>
        <div class="grid-cont mt-8">
          <div class="edit__form">
            <r-input
              class=""
              :error="!address"
              label="Адрес или название уборки"
              v-model="address"
              @input="setClusterByAddress()"
            />
            <r-textarea
              class="mt-6"
              label="Описание"
              :rows="5"
              v-model="description"
              @input="isChange('description')"
            />
            <h3 class="mt-7 mb-4">
              Тип территории
            </h3>
            <r-radio-button
              class="mr-6 mb-3"
              v-model="selectedCategory"
              val="street"
              title="Улица"
              @input="onChangeType(), isChange('selectedCategory')"
            />
            <r-radio-button
              class="mr-6"
              v-model="selectedCategory"
              val="yard"
              title="Двор"
              @input="onChangeType(), isChange('selectedCategory')"
            />
            <h3 class="mt-7 mb-4">
              Расположение
            </h3>
            <r-radio-button
              class="mr-6 mb-3"
              :value="selectedDraw"
              title="Указать вручную"
              @input="onChangeType(), isChange('selectedCategory'), selectedDraw = true"
            />
            <r-radio-button
              class="mr-6"
              :value="!selectedDraw"
              title="Выбрать существующие"
              @input="onChangeType(), isChange('selectedCategory'), selectedDraw = false"
            />
            <div
              class="mt-4 flex align-start"
              v-if="!selectedDraw"
            >
              <div class="flex-1 mr-2">
                <r-select
                  :key="'select_' + countMap"
                  label="Объект"
                  :items="itemsList"
                  v-model="items"
                  is-search
                  return-object
                />
                <div :key="countMap">
                  <contact-items
                    class="mt-4"
                    :icon="'attach'"
                    text-value="title"
                    sub-value="title"
                    @input="removeItem"
                    v-model="selected"
                  />
                </div>
              </div>
              <r-button
                @click="addItem"
                icon="add"
                :title="''"
              />
            </div>
          </div>
          <div class="edit__map">
            <r-date-picker
              label="Выберите даты уборки"
              v-model="calendarDate"
              @input="onClickDate"
              :is-input="false"
              :selected="cleaningDates"
              :click-close="isEditPage"
              :error="(!isEditPage && !cleaningDates.length) || (isEditPage && !calendarDate)"
            />
            <r-select
              class="flex-1 mt-4"
              :items="objectDate"
              label="Время уборки"
              v-model="selectedDate"
            />
            <div class="h-250">
              <div
                class="selected-dates"
                v-if="!!formatDates.length"
              >
                <div
                  class="selected-dates__badge"
                  v-for="(date, index) in formatDates"
                  :key="index"
                >
                  {{ date }}

                  <button
                    class="selected-dates__clear"
                    @click="clearDate(index)"
                  >
                    <r-icon
                      icon="close"
                      fill="amelie"
                      size="16"
                    />
                  </button>
                </div>
              </div>
            </div>
            <div class="text-footer mb-4">
              Добавьте контрольные точки маршрута на карту
            </div>
            <rir-map-leaflet
              @clickMap="onMapClick"
              @mapInit="onMapInit"
              @clickDb="onMapDbClick"
              @clickR="onMapRightClick"
              @mapCoord="mapCoord"
              style="height: 400px;"
              v-if="isShowMap"
              :key="countMap"
              collapse-btn
              :street=" selectedCategory == 'yard' ? true : false"
              :center="coordinates && coordinates.length > 0
                ? (selectedCategory == 'yard' ? markers[0].position :
                  (coordinates[0][0] ? coordinates[0][0] :$cityCenter)
                ) : $cityCenter
              "
            >
              <div
                v-if="coordinates && selectedCategory == 'yard' && selectedDraw"
                :key="countMarker"
              >
                <l-marker
                  v-for="(marker, index) in markers"
                  :key="marker.id"
                  :visible="marker.visible"
                  :draggable="marker.draggable"
                  :lat-lng.sync="marker.position"
                  @click.right="markerRight($event, index)"
                >
                  <l-icon
                    :icon-url="$markerIconTwo.imageHref"
                  />
                </l-marker>
              </div>
              <div v-for="cleaning in selected">
                <l-marker
                  :lat-lng="cleaning.lat ? [cleaning.lat, cleaning.lng] : cleaning.geometry"
                  v-if="cleaning && cleaning.type === 'yard' && selectedCategory != 'street'"
                >
                  <l-icon
                    :icon-url="$markerIconTwo.imageHref"
                  />
                </l-marker>
                <div
                  v-else-if="cleaning && cleaning.geometry2 && cleaning.geometry2[0][0][0] && cleaning.type === 'street' && selectedCategory == 'street'"
                  v-for="line in cleaning.geometry2"
                >
                  <l-polyline
                    :lat-lngs="line"
                    :color="'#4480F3'"
                  />
                </div>
                <div
                  v-else-if="cleaning && cleaning.geometry2 && cleaning.geometry2[0][0][0] == undefined && cleaning.type === 'street' && selectedCategory == 'street'"
                >
                  <l-polyline
                    :lat-lngs="cleaning.geometry2"
                    :color="'#4480F3'"
                  />
                </div>

                <l-polyline
                  v-else-if="cleaning && !cleaning.geometry2 && cleaning.geometry && cleaning.type === 'street' && selectedCategory == 'street'"
                  :lat-lngs="cleaning.geometry"
                  :color="'#4480F3'"
                />
              </div>
            </rir-map-leaflet>
          </div>
        </div>
        <div
          class="flex align-center custom mt-10 mb-60px pointer fargo-hover mb-20"
          @click="deleteItem"
          v-if="isEditPage"
        >
          <r-icon
            icon="delete"
            fill="fargo"
            size="16"
          />
          <div class="fargo--text ml-2">
            Удалить уборку
          </div>
        </div>
        <div class="button_container mt-10 work-form__btn-cont">
          <r-button
            width="wide"
            :title="isEditPage ? 'Сохранить' : 'Добавить'"
            class="flex-1"
            @click="submit"
            :disabled="isSave"
          />
        </div>
      </div>
      <r-modal
        ref="modal"
        close-icon
        fullscreen
      />
    </div>
  </div>
</template>

<script>
import contactItems from '@/components/ContactItems.vue';
import Loader from '@/components/Loader.vue';
import RirMapLeaflet from '@/components/RirMapLeaflet.vue';
import { LMarker, LPolyline, LIcon } from 'vue2-leaflet';
import { EditablePolyline } from 'vue2-leaflet-editable';
import ErrorModal from '@/components/ErrorModal.vue';
import SaveModal from '../components/SaveModal';
import DeleteModal from '../components/DeleteModal';
import PlaceApi from '../api/PlaceApi';

export default {
  components: {
    EditablePolyline,
    LMarker,
    LPolyline,
    LIcon,
    contactItems,
    Loader,
    RirMapLeaflet
  },
  data() {
    return {
      mark: true,
      polyline: null,
      markers: [],
      points: '',
      selectedDraw: true,
      isDraw: true,
      count: 0,
      list: [],
      items: null,
      selectedDate: 'one',
      objectDate: [
        { id: 'one', title: '9:00 - 17:00' },
        { id: 'too', title: '00:00 - 06:00' }
      ],
      selected: [],
      objectTypes: [
        { id: 'street', title: 'Улица' },
        { id: 'yard', title: 'Двор' }
      ],
      pol: [],
      streetGeometry: null,
      calendarDate: null,
      cleaningDates: [],
      mapInstance: null,
      description: null,
      selectedCategory: 'street',
      address: null,

      isLoading: false,
      openWarn: false,
      coordinates: [],
      isShowMap: false,
      isTypeChanged: false,
      flyButton: false,
      isSave: true,
      initialData: {},
      countMap: 0,
      mapIs: true,
      for404: null,
      extraIds: null,
      latMarker: null,
      lngMarker: null,
      countMarker: 0
    };
  },

  computed: {
    itemsList() {
      let items = this.$store?.state?.saveInfo;
      this.items = null;
      if (items != null) {
        items = items.map(el => ({ ...el, id: el.id, title: el.address, value: el.address }));
      } else {
        items = [];
      }
      if (this.selectedCategory == 'yard') {
        items = items.filter(el => el?.type == 'yard');
      } else {
        items = items.filter(el => el?.type == 'street');
      }

      return items;
    },
    isLoadings() {
      return this.$store.state.isObjectLoading;
    },
    isCanSave() {
      return this.address && (this.cleaningDates.length || this.calendarDate);
    },
    isEditPage() {
      return this.$route.name === 'edit' && !this.isCopy;
    },
    isCopy() {
      return this.$route.name === 'copy';
    },
    id() {
      return this.isEditPage ? this.$route.params.id : null;
    },
    formatDates() {
      return this.cleaningDates.map(date => {
        const nDate = new Date(date);
        return nDate.toLocaleDateString('RU-ru');
      });
    }
  },
  watch: {
    markers: {
      immediate: true,
      deep: true,
      handler() {
        if (this.mark) {
          this.setAddressByCluster();
        }
      }
    }
  },
  async mounted() {
    const recaptchaScript = document.createElement('script');
    recaptchaScript.setAttribute(
      'src',
      'https://rawgit.com/Leaflet/Leaflet.Editable/master/src/Leaflet.Editable.js'
    );
    document.head.appendChild(recaptchaScript);
  },
  updated() {
    if (this.$refs.content.offsetHeight > window.innerHeight) {
      this.flyButton = true;
    } else {
      this.flyButton = false;
    }
  },

  async activated() {
    const _this = this;
    const toDay = new Date();
    toDay.setDate(toDay.getDate());

    this.$store.dispatch('getCleaningEdit', { id: this.$route.params.id }).then(value => {
      this.resetData();
      (this.isEditPage || this.isCopy) && this.setData();
      this.isShowMap = true;
      _this.$store.dispatch('loadInfoSave').then(() => {
        _this.selected = [];
        const items = _this.$store?.state?.saveInfo;
        _this.funcSelect(items);
      });
    });
  },

  deactivated() {
    this.isShowMap = false;
  },
  methods: {
    funcSelect(items) {
      const _this = this;
      const clean = _this.$store.getters.getCleanById(_this.$route.params.id);

      _this.selectedDraw = clean?.details?.selectedDraw != undefined ? clean?.details?.selectedDraw : true;
      if (clean?.extraIds && !_this.selectedDraw) {
        _this.selected = items.map(el => {
          if (clean.extraIds.includes(el.id)) {
            _this.isDraw = false;
            _this.pol.forEach(el => {
              el.disableEdit();
            });
            return {
              ...el,
              geometry: (el?.geometry
                ? (typeof el.geometry[0] !== 'string' && typeof el.geometry[0][0] !== 'string'
                  ? el.geometry
                  : [el.geometry]
                )
                : []),
              id: el.id,
              title: el.address,
              value: el.address
            };
          }
        });
      }
      this.selected = this.selected.filter(el => (el != undefined));
      if (this.selected.length == 0) {
        this.selected = [];
      }
    },
    addItem() {
      const item = this.itemsList.filter(el => (el.id == this.items.id));
      const selected = this.selected.filter(el => (el.id == this.items.id));
      if (selected.length == 0) {
        this.selected.push(item[0]);
        console.log(this.selected);
        this.isDraw = false;
      }
    },
    removeItem(arr) {
      this.selected = arr;
      this.isDraw = false;
    },
    formatDate(date) {
      date = new Date(date);
      const dd = date.getDate();
      const mm = date.getMonth() + 1;
      const yy = date.getFullYear();

      return `${dd}.${mm}.${yy}`;
    },
    linkClick() {
      const r = this.$router.resolve({
        name: 'index',
        params: {}
      });
      history.pushState({}, '', r.href);
    },
    submit() {
      if (this.isEditPage) {
        this.OpenSaveModal();
      } else {
        this.onSave();
      }
    },
    async OpenSaveModal() {
      await this.$refs.modal.openModal(SaveModal, {
        saveFunc: this.onSave,
        title: this.address
      });
    },
    async deleteItem() {
      await this.$refs.modal.openModal(DeleteModal, {
        id: Number(this.$route.params.id),
        actionType: 'deleteClean',
        address: this.address
      });
    },
    isChange(field) {
      if (field == 'selectedCategory') {
        this.selected = [];
        this.countMap++;
      }
      if (this.initialData[field] === this[field]) {
        this.isSave = true;
      } else {
        this.isSave = false;
      }
    },
    clearDate(index) {
      this.cleaningDates.splice(index, 1);
    },
    onChangeType() {
      this.isTypeChanged = true;
      this.coordinates = [];
      this.streetGeometry = null;
      this.selected = [];
      this.pol = [];
      this.markers = [];
      const newMarker = {
        position: this.$cityCenter,
        draggable: true,
        visible: true
      };
      if (this.markers.length == 0) {
        this.markers.push(newMarker);
      }

      this.countMap++;
    },
    mapCoord(coord) {
      this.latMarker = coord.lat;
      this.lngMarker = coord.lng;
    },
    onMapInit(e) {
      const _this = this;
      this.mapInstance = e;

      if (this.selectedCategory !== 'yard' && this.selectedDraw) {
        if (!this.coordinates || this.coordinates.length == 0) {
          this.pol[0] = this.mapInstance.mapObject.editTools.startPolyline();
          this.pol[0].on('click', e => {
            _this.pol.forEach(el => {
              el.disableEdit();
            });
            e.target.enableEdit();
            e.target.editor.continueForward();
          });
          this.pol[0].on('contextmenu', e => {
            _this.pol.splice(0, 1);
            e.target.remove(_this.mapInstance);
          });
        }
      }
      if (this.selectedCategory != 'yard' && _this.mapInstance.mapObject) {
        if (this.coordinates == undefined) {
          this.coordinates = [this.$cityCenter];
        }

        if (this.coordinates && this.coordinates.length) {
          this.coordinates.map(items => {
            _this.pol.push(L.polyline(items).addTo(_this.mapInstance.mapObject));
            _this.pol[_this.pol.length - 1].on('click', e => {
              _this.pol.forEach(el => {
                el.disableEdit();
              });
              e.target.enableEdit();
              e.target.editor.continueForward();
            });
            _this.pol[_this.pol.length - 1].on('contextmenu', e => {
              _this.pol.splice(_this.pol.length - 1, 1);
              _this.pol.forEach(el => {
                el.disableEdit();
              });
              e.target.remove(_this.mapInstance);
            });
            _this.pol.forEach(el => {
              el.disableEdit();
            });
          });
        }
      }
      console.log(this.selectedCategory, this.coordinates);
    },
    markerRight(e, index) {
      if (this.selectedDraw) {
        e.target.remove(this.mapInstance);
        this.markers.splice(index, 1);
      }

      this.countMarker++;
    },
    onClickDate(date) {
      if (!date || this.isEditPage) return;

      const index = this.cleaningDates.findIndex(el => el === date);

      if (index !== -1) {
        this.cleaningDates.splice(index, 1);
      } else {
        this.cleaningDates.push(date);
      }

      this.isChange('calendarDate');
    },

    markersWasAdd(e) {
      if (!e[0].clusterName && this.isDraw) {
        this.polyLine = e[0];
        e[0].editor.startDrawing();
      }

      if (this.selectedCategory === 'yard') this.setAddressByCluster(e);
    },

    async setAddressByCluster() {
      if (this.selectedCategory !== 'yard') return;

      const api = new PlaceApi();
      const geo = await api.getGeoSearch({ lat: this.markers[0].position.lat, lng: this.markers[0].position.lng });

      const { address } = geo;
      this.mark = true;
      if (address) this.address = address;
    },

    async setClusterByAddress() {
      this.isSave = false;
      if (this.selectedCategory !== 'yard') return;

      const api = new PlaceApi();
      const geo = await api.getGeoSearch({ address: this.address });

      const { lat, lng } = geo;
      this.mark = false;
      if(!geo?.error) {
        this.markers[0].position = {lat, lng};
      }
      this.mark = true;
      this.isChange('coordinates');
    },

    onMapClick() {
      if (this.selectedCategory == 'street') {
        this.isSave = false;
      }
    },
    onMapDbClick() {
      if (this.selectedDraw) {
        this.pol.forEach(el => {
          el.disableEdit();
        });
      }
    },
    onMapRightClick() {
      if (this.selectedCategory == 'yard') {
        if (this.selectedDraw) {
          const newMarker = {
            position: [this.latMarker, this.lngMarker],
            draggable: true,
            visible: true
          };
          this.markers.push(newMarker);
          this.countMarker++;
        }
      } else if (this.selectedDraw) {
        const _this = this;
        this.pol.forEach(el => {
          el.disableEdit();
        });
        this.pol.push(this.mapInstance.mapObject.editTools.startPolyline());
        this.pol[this.pol.length - 1].on('click', e => {
          _this.pol.forEach(el => {
            el.disableEdit();
          });
          e.target.enableEdit();
          e.target.editor.continueForward();
        });

        this.pol[this.pol.length - 1].on('contextmenu', e => {
          _this.pol.splice(_this.pol.length - 1, 1);
          _this.pol.forEach(el => {
            el.disableEdit();
          });
          e.target.remove(_this.mapInstance);
        });
      }
    },
    onPolyLineClick(e) {
      this.polyLine = e.originalEvent.target;
      e.originalEvent.target.editor.startEditing();
    },

    geometryChange(e) {
      this.isSave = false;
      this.streetGeometry = e.originalEvent.target.geometry.getCoordinates();
    },
    timeout() {
      setTimeout(() => this.for404, 2000);
    },
    async onSave() {
      const _this = this;
      if (!this.isCanSave) return;

      this.isLoading = true;

      const format = date => new Date(date).toLocaleString('ru-Ru', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
      });

      const formatedDates = this.cleaningDates.map(format);
      let items = null;
      if (!this.selectedDraw) {
        items = this.selected.map(el => el.id);
      }
      let pol = [];
      if (this.selected.length > 0) {
        pol = [];
        this.streetGeometry = [];
        if (this.selected) {
          console.log(this.selected);
          this.selected.forEach(items => {
            if (items.geometry.length > 0) {
              pol.push(items.geometry);
            }
          });
        }
      }
      if (this.pol) {
        this.streetGeometry = [];
        this.pol.forEach(items => {
          _this.streetGeometry = [];
          for (let i = 0; i < items._latlngs.length; i++) {
            const item = JSON.parse(
              String(items._latlngs[i])
                .split('LatLng(')
                .join('[')
                .split(')')
                .join(']')
            );
            _this.streetGeometry.push([item[0], item[1]]);
          }
          if (_this.streetGeometry.length > 0) {
            pol.push(_this.streetGeometry);
          }
        });
      }

      let from = '00:00';
      let to = '06:00';
      if (this.selectedDate == 'one') {
        from = '09:00';
        to = '17:00';
      }
      const markers = [];
      this.markers.forEach(items => {
        if (items.position?.lat) {
          markers.push([items.position?.lat, items.position?.lng]);
        } else {
          markers.push(items.position);
        }
      });
      const data = {
        address: this.address,
        description: this.description,
        type: this.selectedCategory,
        geometry2: this.selectedCategory == 'street' ? (pol.length > 0 ? pol
          : (this.selected[0]?.geometry ? this.selected[0]?.geometry : ''))
          : (pol.length == 0 || this.selectedDraw
            ? markers
            : pol
          ),
        extraIds: items?.length > 0 ? items : [],
        details: {
          from,
          to,
          selectedDate: this.selectedDate,
          selectedDraw: this.selectedDraw
        }
      };

      if (this.isEditPage) {
        data.day = this.calendarDate;
      } else if (this.isCopy) {
        data.date = this.calendarDate;
      } else {
        data.date = formatedDates.join();
      }

      this.$store.dispatch('setCleaning', { id: this.id, data }).then(res => {
        if (res?.error) {
          _this.$refs.modal.openModal(ErrorModal, {
            title: res.error
          });
        } else {
          _this.$store.dispatch('getCleaningEdit');
          _this.isLoading = false;
          const r = _this.$router.resolve({
            name: 'index'
          });

          window.location.assign(r.href);
        }
      });
    },

    setData() {
      const _this = this;
      const clean = this.$store.getters.getCleanById(this.$route.params.id);
      if (clean == undefined) {
        this.for404 = true;
      } else {
        this.for404 = false;
      }
      this.address = clean.address ? clean.address : null;
      this.calendarDate = this.isEditPage ? clean.day : null;
      this.streetGeometry = clean.type === 'street' ? clean.geometry2 : null;
      this.selectedCategory = clean.type;
      this.description = clean.description;
      this.coordinates = clean.geometry2
        ? (typeof clean.geometry2[0] === 'object' && typeof clean.geometry2[0][0] === 'object'
          ? clean?.geometry2 : [clean?.geometry2])
        : (clean?.geometry
          ? (typeof clean.geometry[0] === 'object' && typeof clean.geometry[0][0] === 'object'
            ? clean?.geometry : [clean?.geometry])
          : []);
      console.log('coods',this.coordinates);
      this.coordinates2 = clean.geometry2 ? clean?.geometry2 : clean?.geometry;
      this.initialData = JSON.parse(JSON.stringify(clean));
      this.selectedDate = clean.details?.selectedDate ? clean.details.selectedDate : 'one';

      if (clean.type === 'yard' && clean?.details?.selectedDraw) {
        this.coordinates.forEach(item => {
          const addMarker = {
            position: typeof item[0] === 'object' ? item[0] : item,
            draggable: true,
            visible: true
          };
          _this.markers.push(addMarker);
        });
      } else {
        this.markers = [];
        const newMarker = {
          position: this.coordinates.length > 0 && typeof this.coordinates[0] !== 'string' ? (typeof this.coordinates[0][0] !== 'string' ?
            (typeof this.coordinates[0][0][0] !== 'string' ? this.coordinates[0][0] : this.coordinates[0]) : this.$cityCenter) : this.$cityCenter,
          draggable: true,
          visible: true
        };
        this.markers.push(newMarker);
        console.log('newMarker',this.markers);
      }
      this.countMap++;
    },

    resetData() {
      this.isTypeChanged = false;
      this.isLoading = false;
      this.streetGeometry = null;
      this.polyLine = null;
      this.calendarDate = null;
      this.cleaningDates = [];
      this.mapInstance = null;
      this.description = null;
      this.selectedCategory = 'street';
      this.address = null;
      this.coordinates = [];
    }
  }
};
</script>

<style lang="scss" scoped>
.button_container {
  margin-top: 30px;
  padding-top: 32px;
}

.RButton{
  display: flex;
  justify-content: center;
  align-items: center !important;
}

.button_fly {
  padding-bottom: 112px;

  .button_container {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 15;
    width: 100%;
    max-width: calc(100% - 25.3%);
    background: #FFFFFF;
    box-shadow: 0px 8px 32px rgba(4, 21, 62, 0.16);
    padding-left: 31px;
    padding-right: 40px;
    padding-bottom: 40px;
  }
}

.dates {
  display: grid;
  grid-gap: 10px 0;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.grid-cont {
  grid-column-gap: 24px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.edit {
  &__map {
    width: 100%;
    overflow: hidden;
    height: 100%;
  }
}

.selected-dates {
  margin-top: 24px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  &__container {
    font-size: 12px;
    background-color: var(--rir-alien);
    color: var(--rir-amelie);
    padding: 4px 8px;
    border-radius: 50px;
  }

  &__badge {
    padding: 4px 8px;
    border-radius: 30px;
    background-color: var(--rir-alien);
    color: var(--rir-amelie);
    font-size: 14px;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;

    &:hover .selected-dates__clear {
      opacity: 1;
      visibility: visible;
    }
  }

  &__clear {
    position: absolute;
    top: 0;
    right: 0;
    align-self: stretch;
    height: 100%;
    width: 40%;
    background-image: linear-gradient(90deg, transparent, var(--rir-alien) 70%);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
    cursor: pointer;
  }
}

::v-deep .rir-map__legend {
  display: none;
}

.work-form__btn-cont {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 74.7%;
  background-color: #fff;
  z-index: 2;
  padding: 26px 40px 26px 32px;
  box-shadow: 0 9px 28px rgb(17 48 121 / 18%);
}

.text-footer {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #04153E;
  opacity: 0.48;
}

.rir-date-picker__content {
  top: 143px !important;
  margin-top: 45px;
  right: 40px !important;
}

.h-250 {
  height: 250px;
  display: table;
}
</style>
<style>
input:-webkit-autofill, input:focus, textarea:-webkit-autofill, textarea:focus, select:-webkit-autofill, select:focus {
  background-color:transparent !important;
}

.fargo {
  background-color: unset !important;
}
</style>
