<template>
  <div class="objects">
    <div
      class="load-change"
      v-show="showUpload"
    >
      <div class="image-load">
        <img src="../../public/map/load.png">
      </div>
      <div
        class="load-text load-text-error"
        v-if="error"
      >
        Ошибка загрузки данных.
        <div class="error-load">
          {{ errors }}
        </div>
      </div>
      <div
        class="load-text load-text-end"
        v-else
      >
        Данные успешно загружены.
      </div>
    </div>
    <div
      v-show="uploads"
      class="uploads"
      style="display: block !important;"
    >
      <div>
        <div
          @click="Close()"
          class="ml-auto w-20"
        >
          <r-icon
            class="margin-right"
            icon="close"
            fill="rocky"
            size="20"
          />
        </div>
        <div class="parent-save align-center">
          <div class="container-upload">
            <div
              class="mb-6 table__display"
            >
              <r-icon
                class="mr-2 margin-right"
                size="32"
                icon="selected"
                fill="matrix"
              />
            </div>
            <h1 class="ricotta mb-1">
              Загружено
            </h1>
            <div class="date">
              Данные улиц успешно загружены.
            </div>
            <div class="flex mt-8 mb-4">
              <r-button
                type-button="button"
                size="helike"
                width="wide"
                type="secondary"
                color="rocky"
                title="Отлично"
                @click="Close"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-show="upload"
      class="upload"
    >
      <div>
        <div
          class="flex align-center sulguni mb-7 mt-5 ml-3 rocky--text pointer"
          v-if="next2 && !isLoadingNext"
          @click="loadNext()"
        >
          <r-icon
            class="mr-2 mt-2px"
            icon="arrow-left"
            fill="rocky"
            size="16"
          />
          к загрузке файла
        </div>
        <div
          @click="upload = !upload"
          v-if="next1"
        >
          <r-icon
            class="mr-2 margin-right"
            icon="close"
            size="20"
            fill="rocky"
          />
        </div>
        <div
          class="parent align-center"
          v-if="next1"
        >
          <div
            class="container-upload"
            v-if="!load"
          >
            <h1 class="ricotta mb-6">
              Новые данные
            </h1>
            <upload-file
              ref="childComponent"
              @uploadEvent="uploadFile"
              @uploadEventFrom="uploadFileTrue"
              @uploadError="uploadError"
              @uploadSave="savesModels"
              @purge="purgeFunc"
              :accept-file="['.xls, .xlsx']"
              :count-file="2"
              upload-url="/ajax.php?action=universalImport"
              :value="[]"
              :count-line="1"
              description="или перетяните сюда файл xls, созданый на основе шаблона"
            />
            <div class="flex mt-8">
              <r-button
                :title="'Загрузить и добавить уборку'"
                class="flex-1"
                @click="clickUpload()"
              />
            </div>
          </div>
        </div>
        <loader-model v-if="next2 && isLoadingNext && !uploads" />
        <div
          class="parent_next align-center"
          v-if="next2"
        >
          <div
            class="container-upload w-91"
          >
            <h1>Новая уборка</h1>
            <div class="grid-cont mt-8">
              <div class="edit__form">
                <r-input
                  class="flex-1 float custom w-100"
                  value=""
                  :label="'Адрес'"
                  :params-input="{&quot;type&quot;:&quot;input&quot;}"
                  v-model="nameData"
                />
                <div class="mt-3">
                  <r-date-picker
                    style=""
                    click-close
                    label="Дата"
                    v-model="selectedData"
                    @input="searchInfo()"
                    :start-date="String(fromDay)"
                    :end-date="String(items.debug.daysRowSanitized[items.debug.daysRowSanitized.length - 1][1])"
                  />
                </div>
                <r-textarea
                  class="mt-3"
                  label="Описание"
                  :rows="6"
                  v-model="description"
                  :max-rows="6"
                />
                <div class="time mt-3">
                  Временной период
                </div>
                <div class="date_time mt-1">
                  {{ items.startDate }} – {{ formatDate(items.debug.daysRowSanitized[items.debug.daysRowSanitized.length - 1][1]) }}
                </div>
                <div class="date_text mt-6">
                  Сопоставьте загруженные наименования улиц из файла с сохранёнными улицами в базе данных или создайте новую. Улицы отобразятся на карте.
                </div>
              </div>

              <div class="edit__map">
                <rir-map-leaflet
                  collapse-btn
                  :legend="false"
                >
                  <div
                    v-for="cleaning in willSave2"
                  >
                    <v-marker-cluster
                      :options="{showCoverageOnHover: false}"
                    >
                      <l-marker
                        v-if="items.cleaningDocType != 'street' && cleaning.addressCoord"
                        :lat-lng="cleaning.addressCoord"
                      >
                        <l-icon
                          :icon-url="$markerIcon('marker').imageHref"
                        />
                      </l-marker>
                    </v-marker-cluster>
                    <l-polyline
                      v-if="items.cleaningDocType == 'street' && cleaning.addressGeometry != null
                            && cleaning.addressGeometry[0] != null
                            && cleaning.addressGeometry[0][0] != null"
                      :lat-lngs="cleaning.addressGeometry"
                      :color="'#57A003'"
                    />
                  </div>
                </rir-map-leaflet>
              </div>
            </div>
            <div
              class="scroll-x scr xlsx"
            >
              <div class="table mt-7 text-style">
                <div class="table table-line-head text-style">
                  <div class="table-text table flex pt-2 object-text">
                    Из xlsx файла
                  </div>
                  <div class="table-text table flex pt-2 object-text">
                    Сохранённые улицы
                  </div>
                  <div
                    :class="(dayWeekNext[index] == 'Сб' || dayWeekNext[index] == 'Вс') ? 'style-text-color align-center style-text' : 'align-center style-text'"
                    v-for="(date, index) in dateNext"
                  >
                    {{ date }}
                    <span class="text-style-span">{{ dayWeekNext[index] }}</span>
                  </div>
                </div>
              </div>
              <div
                class="scroll-y scr h-320"
                :key="count"
              >
                <div
                  class="pointer table table-body"
                  v-for="(item, index) in saveItems"
                >
                  <div class="table table-line">
                    <div class="table-text table flex">
                      {{ item.address }}
                    </div>
                    <div class="table-text table flex">
                      <rir-select-item
                        label="Подразделение"
                        :items="itemsFunc"
                        v-model="item.recognizedAddress"
                        :xls-new="item.recognizedAddress"
                        :xls="item.address"
                        return-object
                      >
                        <template>
                          <button class="pointer text-rocky sulguni mr-2">
                            Подразделение
                          </button>
                        </template>
                      </rir-select-item>
                    </div>
                    <div
                      class="align-center ml-2 mr-9px"
                      v-for="(dates, indexs) in dateNext"
                    >
                      <span :class="'legend__color ' + caledrarColorAdd(dates, item.date, null) + ' mt-2'" />
                    </div>
                  </div>
                </div>
                <div
                  class="pointer table table-body"
                  v-for="(item, index) in willSave2"
                >
                  <div class="table table-line">
                    <div class="table-text table flex">
                      {{ item.xlsAddress }}
                    </div>
                    <div class="table-text table flex">
                      <rir-select-item
                        label="Подразделение"
                        :items="itemsFunc"
                        v-model="item.recognizedAddress"
                        :xls-new="item.recognizedAddress"
                        :xls="item.xlsAddress"
                        return-object
                      >
                        <template>
                          <button class="pointer text-rocky sulguni mr-2">
                            Подразделение
                          </button>
                        </template>
                      </rir-select-item>
                    </div>
                    <div
                      class="align-center ml-2 mr-9px"
                      v-for="(dates, indexs) in dateNext"
                    >
                      <span :class="'legend__color ' + caledrarColorSave(dates, item.recognizedDates, item.day) + ' mt-2'" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <upload-file
              ref="childComponent"
              @uploadEvent="uploadFile"
              @uploadEventFrom="uploadFileTrue"
              @uploadError="uploadError"
              @uploadSave="savesModels"
              :accept-file="['.xls, .xlsx']"
              :count-file="2"
              :info="false"
              upload-url="/ajax.php?action=universalImport"
              :value="[]"
              :save-purge="purge"
              :count-line="1"
              description="или перетяните сюда файл xls, созданый на основе шаблона"
            />
            <div class="flex mt-8 pb-8">
              <r-button
                :title="'Добавить'"
                width="wide"
                class="flex mr-3"
                :disabled="nameData == null || selectedData == null"
                @click="submit()"
              />
              <r-button
                :title="'Записать'"
                width="wide"
                class="flex"
                @click="clickUpload(true)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <h1 class="ricotta mb-7">
      {{ titleInfo() }}
    </h1>
    <r-tabs
      class="mt-7"
      :items="tabItems"
      @input="tab"
      v-model="activeTabId"
    />
    <empty v-show="$store.state.isEmpty">
      <router-link
        class="flex align-center sulguni rocky--text"
        :to="{ name: 'new' }"
        v-if="activeTabId.id == 'published'"
      >
        <r-icon
          class="mr-2"
          icon="add"
          fill="rocky"
          size="16"
        />
        Добавить вручную
      </router-link>
      <router-link
        class="flex align-center sulguni rocky--text"
        :to="{ name: 'new-clear' }"
        v-if="activeTabId.id == 'moderation'"
      >
        <r-icon
          class="mr-2"
          icon="add"
          fill="rocky"
          size="16"
        />
        Добавить мероприятие
      </router-link>
    </empty>
    <div v-show="!this.$store.state.isEmpty">
      <div
        class="mt-6 flex"
        v-if="activeTabId.id == 'published'"
      >
        <router-link
          class="flex mr-5 align-center sulguni rocky--text"
          :to="{ name: 'new' }"
        >
          <r-icon
            class="mr-2"
            icon="add"
            fill="rocky"
            size="16"
          />
          Добавить вручную
        </router-link>
        <!-- <a class="flex align-center sulguni ml-6 text-rocky" href=""
          ><rir-icon class="mr-2" icon="add" />Загрузить данные</a
        > -->
        <div
          class=" mr-5 flex align-center sulguni rocky--text pointer"
          @click="uploadContainer"
        >
          <r-icon
            class="mr-2"
            icon="add"
            fill="rocky"
            size="16"
          />
          Загрузить данные
        </div>
        <router-link
          class="flex mr-5 align-center sulguni rocky--text"
          :to="{ name: 'save-objects' }"
        >
          <r-icon
            class="mr-2"
            icon="road-sing"
            fill="rocky"
            size="16"
          />
          Сохранённые объекты
        </router-link>
      </div>
      <div
        class="mt-6 flex"
        v-if="activeTabId.id == 'moderation'"
      >
        <router-link
          class="flex align-center sulguni rocky--text"
          :to="{ name: 'new-clear' }"
        >
          <r-icon
            class="mr-2"
            icon="add"
            fill="rocky"
            size="16"
          />
          Добавить мероприятие
        </router-link>
        <!-- <a class="flex align-center sulguni ml-6 text-rocky" href=""
          ><rir-icon class="mr-2" icon="add" />Загрузить данные</a
        > -->
      </div>
      <div class="objects__filter">
        <r-select
          v-if="activeTabId.id !== 'moderation' && activeTabId.id != 'moderationMod'"
          class="flex-1 mr-6"
          :items="objectTypes"
          label="Тип объекта"
          v-model="selectedCategory"
        />
        <r-select
          v-if="activeTabId.id == 'moderation'"
          class="flex-1 mr-6"
          select-all
          select-first-item
          :items="objectTypesStatus"
          label="Статусы"
          v-model="selectedCategoryStatus"
          @input="searchInfo()"
        />
        <div
          class="icon-div mr-6"
          v-if="activeTabId.id !== 'moderation' && activeTabId.id != 'moderationMod'"
        >
          <r-date-picker
            click-close
            label="Дата с"
            :is-clear-model="true"
            v-model="selectedPeriod"
            @input="onChangePeriod"
          />
        </div>
        <div
          class="icon-div mr-6"
          v-if="activeTabId.id !== 'moderation' && activeTabId.id != 'moderationMod'"
        >
          <r-date-picker
            click-close
            label="Дата по"
            :start-date="String(selectedPeriod)"
            v-model="selectedPeriodFrom"
            :is-clear-model="true"
            @input="onChangePeriod"
          />
        </div>
        <div
          class="icon-div  mr-6"
          v-if="activeTabId.id == 'moderation' || activeTabId.id == 'moderationMod'"
        >
          <r-date-picker
            click-close
            label="Дата с"
            :is-clear-model="true"
            v-model="selectedCategoryMer"
            @input="searchInfo()"
          />
        </div>
        <div
          class="icon-div mr-6"
          v-if="activeTabId.id == 'moderation' || activeTabId.id == 'moderationMod'"
        >
          <r-date-picker
            click-close
            label="Дата по"
            :is-clear-model="true"
            v-model="selectedCategoryMerTo"
            @input="searchInfo()"
          />
        </div>
        <r-input
          class="flex-1"
          before-icon="search"
          label="Поиск по адресу"
          v-model="search"
          @input="searchInfo()"
        />

        <div
          class="flex ml-6"
          v-if="activeTabId.id == 'published'"
        >
          <r-button-simple
            size="larishae"
            icon="icon-view"
            icon-size="20"
            @click="tableResize()"
            :type="selectedView == 'table' ? 'primary' : 'secondary'"

          />
          <r-button-simple
            size="larishae"
            class="ml-2"
            icon="geopoint"
            icon-size="20"
            :type="selectedView == 'map' ? 'primary' : 'secondary'"
            @click="mapResize()"
          />
          <r-button-simple
            size="larishae"
            class="ml-2"
            icon="calendar"
            :type="selectedView == 'calendar' ? 'primary' : 'secondary'"
            icon-size="20"
            @click="calendarResize()"
          />
        </div>
        <div
          class="flex ml-6"
          v-if="activeTabId.id == 'moderation' || activeTabId.id == 'moderationMod'"
        >
          <r-button-simple
            size="larishae"
            icon="icon-view"
            icon-size="20"
            @click="tableResizeEvent()"
            :type="selectedViewEvent == 'table' ? 'primary' : 'secondary'"
          />
          <r-button-simple
            size="larishae"
            class="ml-2"
            icon="geopoint"
            icon-size="20"
            :type="selectedViewEvent == 'map' ? 'primary' : 'secondary'"
            @click="mapResizeEvent()"
          />
        </div>
      </div>
      <loading-content v-if="isLoading" />
      <template v-else>
        <div
          class="mt-6 flex"
          :key="countMer"
        >
          <div v-if="activeTabId.id == 'moderation' && selectedViewEvent === 'map'">
            <r-checkbox
              class="mr-4 mt-1"
              v-model="isShowCompleted"
              title="Обращения о мусоре"
              style="float: left;"
            />

            <!-- <DropdownSelect
              class="ml-1 sulguni mr-6"
              :items="problemTypes"
              @select="onProblemSelect"
            /> -->

            <drop-down-date-picker
              class="ml-6 mt-1"
              :start="filterDoneStartDate"
              :end="filterDoneEndDate"
              @changeStart="onChangeStartDate"
              @changeEnd="onChangeEndDate"
            />
          </div>
          <div
            class="flex align-center custom pointer fargo-hover"
            v-if="selectedPeriod && selectedPeriodFrom
              && activeTabId.id !== 'moderation' && activeTabId.id != 'moderationMod'
              && (selectedView === 'calendar' || selectedView === 'table')"
            @click="deletePeriod()"
          >
            <r-icon
              icon="delete"
              fill="fargo"
              size="16"
            />
            <div class="fargo--text ml-2">
              Удалить уборки за выбранный период
            </div>
          </div>
          <div
            class="flex align-center custom pointer rocky-hover ml-6"
            v-if="selectedPeriod && selectedPeriodFrom
              && activeTabId.id !== 'moderation' && activeTabId.id != 'moderationMod'
              && (selectedView === 'calendar' || selectedView === 'table')"
            @click="copyPeriod()"
          >
            <r-icon
              icon="copy"
              fill="rocky"
              size="16"
            />
            <div class="rocky--text ml-2">
              Дублировать уборки за выбранный период
            </div>
          </div>
          <div
            v-if="activeTabId.id == 'moderation' || activeTabId.id == 'moderationMod'"
            class="ml-auto parmigiano text-titanic opacity-48"
          >
            {{ !!searchText ? 'Найдено' : 'Всего' }} {{ countRowMer() }}
          </div>
          <div
            v-else
            class="ml-auto parmigiano text-titanic opacity-48"
          >
            {{ !!searchText ? 'Найдено' : 'Всего' }} {{ countRow }}
          </div>
        </div>
        <div
          v-if="selectedView === 'table' && activeTabId.id == 'published'"
          class="objects__list mt-6"
        >
          <div
            v-for="item in cleaningList"
            v-if="item.type === 'street' || item.type === 'yard'"
          >
            <card
              @delete-card="deleteFunc()"
              :uid="item.id"
              :icon="item.type === 'street' ? 'road-sing' : 'land-locate-buildings'"
              :title="item.type === 'street' ? 'Улица' : 'Двор'"
              :address="item.address"
              :count="item.count"
              :purge-deadline="item.purgeDeadline"
            >
              <div class="flex">
                <r-icon
                  icon="calendar"
                  fill="rocky"
                  size="16"
                />
                <span class="ml-2 briscola rocky--text">{{ item.dayF }}</span>
              </div>
            </card>
          </div>
        </div>
        <div
          v-if="(activeTabId.id == 'moderation' || activeTabId.id == 'moderationMod') && selectedViewEvent === 'table'"
        >
          <loadCardEvent
            ref="cards"
            :active-tab-id="activeTabId.id"
            :check-house="isCheckHouse"
            :is-search="searchText"
            :is-search-status="selectedCategoryStatus"
            :selected-category-mer="selectedCategoryMer"
            :selected-category-mer-to="selectedCategoryMerTo"
          />
        </div>
        <div
          v-if="selectedView === 'map' && activeTabId.id == 'published'"
          :key="countMapEvent"
          class="objects__map mt-6"
          ref="map"
        >
          <rir-map-leaflet
            v-if="isShowMap && activeTabId.id == 'published'"
            v-model="search"
          >
            <v-marker-cluster
              :options="{showCoverageOnHover: false}"
            >
              <div
                v-for="cleaning in cleaningList"
                v-if="cleaning.type === 'yard' && (cleaning.geometry2 || cleaning.geometry)"
              >
                <l-marker
                  v-for="marker in cleaning.geometry2"
                  :lat-lng="marker"
                >
                  <l-icon
                    :icon-url="$markerIcon('cleaning_' + getCleaningStatus(cleaning.day, cleaning?.details)).imageHref"
                  />
                  <l-popup
                   :options="{offset: [110, -15]}"
                  >
                    <balloon-card-clering
                      :id="cleaning.id"
                      :title="cleaning.address"
                      :date="cleaning.day"
                      :address="cleaning.description"
                    />
                  </l-popup>
                </l-marker>
              </div>
            </v-marker-cluster>
            <l-polyline
              v-for="cleaning in cleaningList"
              v-if="cleaning.type === 'street' && (cleaning.geometry2 || cleaning.geometry)"
              :lat-lngs="cleaning.geometry2 ? cleaning.geometry2 : cleaning.geometry"
              :color="mapStrokeColor(cleaning.day, cleaning?.details)"
            >
              <l-popup
                :options="{offset: [110, 15]}"
              >
                <balloon-card-clering
                  :id="cleaning.id"
                  :title="cleaning.address"
                  :date="cleaning.day"
                  :address="cleaning.description"
                />
              </l-popup>
            </l-polyline>
            <l-marker
              v-for="transport of transportData.anims"
              v-if="transport.rtype === 'clearing'"
              :lat-lng="[transport.lat, transport.lon]"
            >
              <l-icon
                :icon-size="[40, 120]"
                :icon-url="`data:image/svg+xml;charset=UTF-8,%3csvg width='52' height='24' viewBox='0 0 52 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M0 8C0 3.58172 3.58172 0 8 0H40C46.6274 0 52 5.37258 52 12V24H8C3.58172 24 0 20.4183 0 16V8Z' fill='%2357A003'/%3e%3cpath d='M14 13.5C14 14.0523 13.5523 14.5 13 14.5C12.4477 14.5 12 14.0523 12 13.5C12 12.9477 12.4477 12.5 13 12.5C13.5523 12.5 14 12.9477 14 13.5Z' fill='white'/%3e%3cpath d='M19 14.5C19.5523 14.5 20 14.0523 20 13.5C20 12.9477 19.5523 12.5 19 12.5C18.4477 12.5 18 12.9477 18 13.5C18 14.0523 18.4477 14.5 19 14.5Z' fill='white'/%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M15 4C14.4477 4 14 4.44772 14 5H11.6805C10.7028 5 9.8684 5.70683 9.70767 6.6712L9.31954 9H9C8.44772 9 8 9.44772 8 10C8 10.5523 8.44772 11 9 11V15C9 15.0441 9.00285 15.0875 9.00837 15.13C8.76241 15.2696 8.5793 15.5113 8.52083 15.8044L8.04169 18.2066C7.8566 19.1346 8.56646 20 9.51271 20H22.4948C23.4422 20 24.1523 19.1327 23.9653 18.2039L23.4818 15.8026C23.4228 15.5091 23.2387 15.2674 22.9918 15.1284C22.9972 15.0864 23 15.0435 23 15V11C23.5523 11 24 10.5523 24 10C24 9.44772 23.5523 9 23 9H22.6805L22.2923 6.6712C22.1316 5.70683 21.2972 5 20.3195 5H18C18 4.44772 17.5523 4 17 4H15ZM11 15V12H21V15H17.5015C16.9492 15 16.5015 15.4477 16.5015 16C16.5015 16.5523 16.9492 17 17.5015 17H21.6828L21.8841 18H10.1223L10.3218 17H14.5015C15.0538 17 15.5015 16.5523 15.5015 16C15.5015 15.4477 15.0538 15 14.5015 15H11ZM11.1805 10H20.8195L20.3195 7L11.6805 7L11.1805 10Z' fill='white'/%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M40 2C34.4772 2 30 6.47715 30 12C30 17.5228 34.4772 22 40 22H50V12C50 6.47715 45.5228 2 40 2ZM40 24H50H52V22V12C52 5.37258 46.6274 0 40 0C33.3726 0 28 5.37258 28 12C28 18.6274 33.3726 24 40 24ZM36 12C36 9.79086 37.7909 8 40 8C42.2091 8 44 9.79086 44 12C44 14.2091 42.2091 16 40 16C37.7909 16 36 14.2091 36 12Z' fill='white'/%3e%3c/svg%3e`"
              />
              <l-popup
                :options="{offset: [110, -15]}"
              >
                <balloon-transport
                  :transport="transport"
                  :data-id="transport.id"
                />
              </l-popup>
            </l-marker>
          </rir-map-leaflet>
        </div>
        <div
          v-if="selectedViewEvent === 'map' && (activeTabId.id == 'moderation' || activeTabId.id == 'moderationMod')"
          :key="countMap"
          class="objects__map mt-6"
          ref="map"
        >
          <rir-map-leaflet
            v-if="isShowMap && (activeTabId.id == 'moderation')"
            :key="countSearch"
            v-model="search"
          >
            <l-marker
              v-for="marker in listAll"
              v-if="isShowCompleted"
              :lat-lng="[`${marker.lat}`, `${marker.lng}`]"
            >
              <l-icon
                :icon-url="'https://storage.rosatom.city/platform-prod/volgodonsk/common/vue/icons/map/problem_type_2.svg'"
              />
              <l-popup
                :options="{offset: [110, -15]}"
              >
                <balloon-card-problem
                  :id="marker.id"
                  :title="marker.title"
                  :address="marker.address"
                  :img="marker?.photos[0]?.url ? marker.photos[0].url : null"
                  :date="marker.created"
                />
              </l-popup>
            </l-marker>
            <v-marker-cluster
              :options="{showCoverageOnHover: false}"
            >
              <l-marker
                v-for="marker in filteredObjects"
                v-if="marker.approved == 1"
                :lat-lng="[`${marker.lan}`, `${marker.lng}`]"
              >
                <l-icon
                  :icon-url="$markerIcon('cleaning_' + (marker.dayF == '-' ? 'done' : getStatus(marker.dayF, marker.dayFEnd, marker.timeTo, marker.timeFrom))).imageHref"
                />
                <l-popup
                  :options="{offset: [110, -15]}"
                >
                  <balloon-card
                    :id="marker.id"
                    :img="marker.img"
                    :title="marker.title"
                    :date="marker.dayF"
                    :address="marker.address"
                  />
                </l-popup>
              </l-marker>
            </v-marker-cluster>
          </rir-map-leaflet>
          <rir-map-leaflet
            v-if="isShowMap && (activeTabId.id == 'moderationMod')"
            :legend="false"
            :key="countSearch"
            v-model="search"
          >
            <v-marker-cluster
              :options="{showCoverageOnHover: false}"
            >
              <l-marker
                v-for="marker in filteredObjects"
                v-if="marker.approved == 0"
                :lat-lng="[`${marker.lan}`, `${marker.lng}`]"
              >
                <l-icon
                  :icon-url="$markerIconTwo.imageHref"
                />
                <l-popup
                  :options="{offset: [110, -15]}"
                >
                  <balloon-card
                    :id="marker.id"
                    :img="marker.img"
                    :title="marker.title"
                    :date="marker.dayF"
                    :address="marker.address"
                  />
                </l-popup>
              </l-marker>
            </v-marker-cluster>
          </rir-map-leaflet>
        </div>
        <not-found
            v-if="(
            (selectedViewEvent != 'map' && (activeTabId.id == 'moderation' || activeTabId.id == 'moderationMod'))) && countRowMer() < 1"
        />
        <not-found
          v-if="(selectedView == 'table' && activeTabId.id == 'published') && countRow < 1"
        />
      </template>
    </div>
    <div
      class="scroll-x grid"
      :key="count"
    >
      <div
        v-if="activeTabId.id == 'published' && selectedView == 'calendar'"
        class="table mt-7 text-style"
      >
        <div class="table table-line-head text-style">
          <div class="table-text table flex pt-2 object-text">
            Объект
          </div>
          <div
            :class="(dayWeek[index] == 'Сб' || dayWeek[index] == 'Вс') ? 'style-text-color align-center style-text' : 'align-center style-text'"
            v-for="(date, index) in date"
          >
            {{ date.index }}
            <span class="text-style-span">{{ dayWeek[index] }}</span>
          </div>
        </div>
      </div>
      <div class="scroll-y">
        <div
          v-if="activeTabId.id == 'published' && selectedView == 'calendar'"
          class="pointer table table-body"
          v-for="(item, index) in calendarList"
        >
          <div class="table table-line">
            <div class="table-text table flex">
              {{ item.address }}
            </div>
            <div
              class="align-center ml-2 mr-9px"
              v-for="(dates, indexs) in date"
              :key="item.addressId + indexs + countIndex"
            >
              <span
                :class="'legend__color pointer '
                  + getSize(colors,item.addressId,dates.day, caledrarColor(dates.index, item.days, dates.day)) + ' '
                  + ' mt-2'"
                @click="clickToggle(item, dates.day, index, dates.index)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="activeTabId.id == 'published' && selectedView == 'calendar'"
      class="r-map__legend mt-8 mb-8"
    >
      <div class="flex">
        <div class="flex">
          <span class="legend__color lebowski--bg" />
          <span class="briscola opacity-72 ml-3">Ожидается уборка</span>
        </div>

        <div class="ml-2 flex">
          <span class="legend__color rocky--bg" />
          <span class="briscola opacity-72 ml-3">Идёт уборка</span>
        </div>

        <div class="ml-2 flex">
          <span class="legend__color matrix--bg" />
          <span class="briscola opacity-72 ml-3">Уборка завершена</span>
        </div>
      </div>
    </div>
    <model-save ref="savesChild" />
    <r-modal
      ref="modal"
      close-icon
    />
  </div>
</template>

<script>
import Card from '@/components/ObjectCard.vue';
import RirMapLeaflet from '@/components/RirMapLeaflet.vue';
import BalloonCard from '@/components/BalloonCard.vue';
import BalloonCardClering from '@/components/BalloonCardClering';
import DropDownDatePicker from '@/components/DropDownDatePicker.vue';
import moment from 'moment';
import UploadFile from '@/components/uploadFile.vue';
import RirSelectItem from '@/components/RirSelectItem.vue';
import PlaceApi from '@/api/PlaceApi';
import { LMarker, LPolyline, LIcon, LPopup } from 'vue2-leaflet';
import L from 'leaflet';
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster';
import BalloonCardProblem from '@/components/BalloonCardProblem.vue';
import DeleteModalPeriod from '@/components/DeleteModalPeriod.vue';
import ModelSave from '@/components/ModelSave.vue';
import LoaderModel from '@/components/LoaderModel.vue';
import Empty from '../components/Empty';
import notFound from '../components/notFound';
import loadCardEvent from '../components/loadEvent.vue';
import BalloonTransport from '../components/BalloonTransport';
import CopyModalPeriod from "@/components/CopyModalPeriod.vue";

export default {
  name: 'ObjectList',
  components: {
    LoaderModel,
    LMarker,
    LPolyline,
    LIcon,
    LPopup,
    ModelSave,
    'v-marker-cluster': Vue2LeafletMarkerCluster,
    UploadFile,
    Card,
    RirMapLeaflet,
    BalloonCard,
    loadCardEvent,
    DropDownDatePicker,
    notFound,
    Empty,
    BalloonCardClering,
    BalloonTransport,
    RirSelectItem,
    BalloonCardProblem
  },
  data() {
    return {
      uploads: false,
      errors: '',
      showUpload: false,
      error: false,
      message: '',
      purge: 0,
      countMap: 0,
      countMapEvent: 0,
      countIndex: 0,
      isLoadingNext: false,
      colors: [],
      itemsFunc: [],
      saveItems: [],
      file: false,
      nameData: null,
      selectedData: null,
      description: null,
      count: 0,
      items: null,
      willSave2: [],
      next1: true,
      next2: false,
      dayWeek: [],
      dayWeekNext: [],
      dayNamber: null,
      dayNamberNext: null,
      days: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
      dateNext: [],
      date: [],
      load: false,
      upload: false,
      isShowCompleted: false,
      componentKey: 0,
      isCheck: false,
      isCheckHouse: null,
      isSearch: '',
      selectedPeriod: null,
      selectedPeriodFrom: null,
      selectedPeriodMer: 'all',
      filterDoneStartDate: null,
      filterDoneEndDate: null,
      countSearch: 0,
      objectTypes: [
        { id: 'all', title: 'Все' },
        { id: 'street', title: 'Улица' },
        { id: 'yard', title: 'Двор' }
      ],
      objectTypesStatus: [
        { id: 'wait', active: true, title: 'Ожидается уборка' },
        { id: 'progress', active: false, title: 'Идет уборка' },
        { id: 'done', active: false, title: 'Уборка завершена' }
      ],

      periodList: [
        { id: 'yesterday', active: false, title: 'Вчера' },
        { id: 'today', active: true, title: 'Сегодня' },
        { id: 'tomorrow', active: false, title: 'Завтра' },
        { id: 'thisWeek', active: false, title: 'Текущая неделя' },
        { id: 'thisMonth', active: false, title: 'Текущий месяц' }
      ],
      periodListMer: [
        { id: 'all', active: true, title: 'Все' },
        { id: 'yesterday', active: false, title: 'Вчера' },
        { id: 'today', active: false, title: 'Сегодня' },
        { id: 'tomorrow', active: false, title: 'Завтра' },
        { id: 'thisWeek', active: false, title: 'Текущая неделя' },
        { id: 'thisMonth', active: false, title: 'Текущий месяц' }
      ],
      countMer: 0,
      sortTypes: [
        {
          id: 1,
          title: 'По названию'
        },
        {
          id: 2,
          title: 'По количеству'
        }
      ],
      selectedView: 'table',
      selectedViewEvent: 'table',
      selectedCategory: 'all',
      selectedCategoryStatus: null,
      selectedCategoryDate: null,
      selectedCategoryMer: null,
      selectedCategoryMerTo: null,
      selectedSortType: 1,
      activeTabId: {
        id: 'published',
        title: 'Плановая уборка',
        optionsBulb: {
          color: 'rocky',
          title: this.countOnPublic,
          position: 'eluno'
        }
      },
      countOnPublic: null,
      countOnModerate: null,
      countOnModerateTo: null,
      searchText: null,
      timeout: null,
      selectedMarkerId: null,
      isShowMap: false,
      fromDay: null,
      socket: null,
      transportData: {
        anims: []
      }
    };
  },
  computed: {
    search: {
      get() {
        return this.searchText;
      },
      set(val) {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.searchText = val;
        }, 420);
      }
    },
    allObjects() {
      let _this = this;
      if (!this.$store.state?.active?.all?.length) return [];

      const objects = this.$store.state.active.all;
      const list = this.$store.state.cleaningList;
      this.countOnPublic = list.length;
      this.countOnModerateTo = 0;
      return objects
        .map(el => {
          if (el?.id) {
            let timeTo = null;
            let timeFrom = null;
            if(el.eventForm?.time) {
              const time = el.eventForm?.time.split(' - ');
              timeTo = _this.formatDateHour(time[0]);
              timeFrom = _this.formatDateHour(time[1]);
            }
            this.countOnModerateTo = el.approved == 0 ? this.countOnModerateTo + 1 : this.countOnModerateTo + 0;
            return {
              id: el.id,
              timeTo: timeTo,
              timeFrom: timeFrom,
              address: el.eventForm ? el.eventForm.address : '-',
              dayF: el.eventForm?.datesExtra ? el.eventForm.datesExtra[0] : '-',
              dayFEnd: el.eventForm?.datesExtra ? el.eventForm.datesExtra[el.eventForm.datesExtra.length - 1] : '-',
              title: el.title,
              img: el.photo0 ? el.photo0 : null,
              lan: el.lat ? el.lat : 47.521196,
              lng: el.lng ? el.lng : 42.180914,
              approved: el.approved
            };
          }
        })
        .sort((a, b) => (b.count < 20) && a.address.localeCompare(b.address));
    },
    filteredObjects() {
      const v = this;
      let list = this.allObjects;

      if (v.selectedCategoryStatus !== null && v.activeTabId.id === 'moderation') {
        list = list.filter(el => v.getStatus(el.dayF, el.dayFEnd, el.timeTo, el.timeFrom) === v.selectedCategoryStatus);
      }

      if (v.search) {
        list = list.filter(el => {
          if (el?.title) {
            return (el.title ? (el.title.toLowerCase()
              .indexOf(v.search.toLowerCase()) >= 0) : false);
          }
          return false;
        });
      }
      if (this.activeTabId.id == 'moderation') {
        list = list.filter(
          el => (el.approved == 1)
        );
      }
      if (this.activeTabId.id == 'moderationMod') {
        list = list.filter(
          el => (el.approved == 0)
        );
      }
      if (list && (this.selectedCategoryMer || this.selectedCategoryMerTo)) {
        list = list.filter(el => moment(new Date(el.dayF)).set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0
        }).isBetween(moment(this.selectedCategoryMer ? new Date(this.selectedCategoryMer) : new Date(null)).set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0
        }), moment(this.selectedCategoryMerTo ? new Date(this.selectedCategoryMerTo) : new Date()).set({
          hour: 23,
          minute: 59,
          second: 59,
          millisecond: 59
        })) === true);
      }
      return list;
    },
    cleaningList() {
      if (!this.$store.state?.cleaningList?.length) return [];
      let list = this.$store.state.cleaningList;
      this.countOnPublic = list.length;

      if (list && this.selectedCategory && this.selectedCategory !== 'all') {
        list = list.filter(el => el.type === this.selectedCategory);
      }

      if (this.searchText) {
        list = list.filter(el => {
          if (el?.address) {
            return ((el.address.toLowerCase()
              .indexOf(this.searchText.toLowerCase()) >= 0));
          }
          return false;
        });
      }
      console.log(1, list);
      return list;
    },
    calendarList() {
      let list = this.$store?.state?.info;

      if (list && this.selectedCategory && this.selectedCategory !== 'all') {
        list = list.filter(el => el.type === this.selectedCategory);
      }

      if (this.searchText) {
        list = list.filter(el => {
          if (el?.address) {
            return ((el.address.toLowerCase()
              .indexOf(this.searchText.toLowerCase()) >= 0));
          }
          return false;
        });
      }

      let listItem = [];
      if (list) {
        list.map(item => {
          if (listItem[item.addressId]) {
            listItem[item.addressId].days.push(item.day);
            listItem[item.addressId].ids[item.day] = item.id;
          } else {
            listItem[item.addressId] = item;
            listItem[item.addressId].days = [];
            listItem[item.addressId].ids = [];
            listItem[item.addressId].days.push(item.day);
            listItem[item.addressId].ids[item.day] = item.id;
          }
        });
        list = [];
        listItem = listItem.map(item => {
          list.push(item);
        });
      }

      return list;
    },
    listAll() {
      let list = this.$store?.state?.all;

      return list.map(el => {
        if (el?.id) {
          return {
            ...el
          };
        }
      });
    },

    isLoading() {
      return false;
    },

    tabItems() {
      const tabs = [
        {
          id: 'published',
          title: 'Плановая уборка',
          optionsBulb: {
            color: 'rocky',
            isShape: false,
            title: '',
            position: 'eluno'
          }
        },
        {
          id: 'moderation',
          title: 'Мероприятия',
          optionsBulb: {
            color: 'fargo',
            isShape: false,
            title: this.countOnModerate > 0 ? String(this.countOnModerate) : '',
            position: 'eluno'
          }
        },
        {
          id: 'moderationMod',
          title: 'Мероприятия на модерации',
          optionsBulb: {
            color: 'fargo',
            isShape: false,
            title: this.countOnModerateTo > 0 ? String(this.countOnModerateTo) : '',
            position: 'eluno'
          },
          countColor: 'fargo'
        }
      ];
      return tabs;
    },
    countRow() {
      if (this.activeTabId.id == 'published') {
        return this.cleaningList.length;
      }
      console.log(this.filteredObjects.length);
      return this.filteredObjects.length;
    }
  },

  activated() {
    this.isShowMap = true;
    if (this.socket) {
      this.socket.open();
    }
  },
  deactivated() {
    this.isShowMap = false;
    if (this.socket) {
      this.socket.close(1000, 'Закрытие окна');
    }
  },
  async created() {
    const host = window.location.host === 'localhost:4000' ? 'test.rosatom.city' : window.location.host;
    this.socket = await new WebSocket(`wss://${host}/transport/rest/ws/online?type=clearing`);// connectionCount=0&

    this.socket.onmessage = e => {
      console.log('[message] Данные получены с сервера');
      this.transportData = JSON.parse(e.data);
    };

    this.socket.onclose = function (event) {
      if (event.wasClean) {
        console.log(`[close] Соединение закрыто чисто, код=${event.code} причина=${event.reason}`);
      } else {
        // например, сервер убил процесс или сеть недоступна
        // обычно в этом случае event.code 1006
        console.log('[close] Соединение прервано');
      }
    };

    this.socket.onerror = function (error) {
      console.log(`[error] ${error.message}`);
    };
  },
  async mounted() {
    const _this = this;
    Date.prototype.daysInMonth = function () {
      return 33 - new Date(this.getFullYear(), this.getMonth(), 33).getDate();
    };
    const week = new Date(new Date().setDate(1));
    this.dayNamber = this.getWeekDay(week);
    const dates = new Date().daysInMonth();

    const dateNext = new Date(new Date().getFullYear(), new Date().getMonth(), dates);
    this.date = [];
    let i = 0;
    for (let s = 1; s < 2;) {
      if (week >= dateNext) {
        s = 2;
      }
      this.date.push({ index: week.getDate(), day: new Date(week.setDate(week.getDate())) });
      week.setDate(week.getDate() + 1);

      this.dayWeek[i] = this.dayCount(this.dayNamber);
      i++;
    }

    if (this.$route.path == '/save') {
      this.activeTabId.id = 'moderation';
    }
    this.$store.dispatch('loadActive').then(() => {
      _this.filteredObjects;
    });
    !this.cleaningList?.length && this.$store.dispatch('getCleaning');
    this.$store.dispatch('loadAll', {
      from: this.filterDoneStartDate ? this.filterDoneStartDate : '',
      to: this.filterDoneEndDate ? this.filterDoneEndDate : ''
    });

    const options = {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric'

    };

    const firstDayCurrYear = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    const lastDayCurrYear = new Date(new Date().getFullYear(), new Date().getMonth(), dates);

    firstDayCurrYear.setDate(firstDayCurrYear.getDate());
    const dayYear = firstDayCurrYear.toLocaleString('ru-Ru', options);

    lastDayCurrYear.setDate(lastDayCurrYear.getDate());
    const dayToYear = lastDayCurrYear.toLocaleString('ru-Ru', options);

    const dayTo = new Date().toLocaleString('ru-Ru', options);
    const date = new Date();
    let dd = date.getDate();
    if (dd < 10) dd = `0${dd}`;
    let mm = date.getMonth() + 1;
    if (mm < 10) mm = `0${mm}`;
    const yy = date.getFullYear();

    const dayStart = `${yy}-${mm}-${dd}`;
    if (!this.selectedPeriod) {
      this.selectedPeriod = dayStart;
    }
    if (!this.selectedPeriodFrom) {
      this.selectedPeriodFrom = dayStart;
    }
    this.$store.dispatch('loadInfo', { dateFrom: dayYear, dateTo: dayToYear });
    this.$store.dispatch('getCleaning', { dateFrom: dayTo, dateTo: dayTo });
  },
  methods: {
    formatDateHour(time) {
      let hh = time.split(':')[0];
      if (hh < 10) hh = hh.substring(1);
      const mm = time.split(':')[1];

      return `${hh}:${mm}`;
    },
    countRowMer() {
      console.log(this.filteredObjects.length);
      return this.filteredObjects.length;
    },
    Close() {
      document.location.reload();
    },
    savesModels() {
      this.uploads = true;
    },
    purgeFunc(item) {
      if (item) {
        this.purge = 1;
      } else {
        this.purge = 0;
      }
    },
    icon(item) {
      return L.icon({
        iconUrl: this.$markerIcon(`cleaning_${this.getCleaningStatus(item)}`),
        iconSize: [26, 42],
        iconAnchor: [13, 42],
        shadowSize: [41, 41],
        shadowAnchor: [13, 41]
      });
    },
    titleInfo() {
      return 'Уборка';
    },
    getSize(item, index, index1, color) {
      return item[index] ? (item[index][index1] ? item[index][index1] : color) : color;
    },
    submit() {
      const _this = this;
      const format = date => new Date(date).toLocaleString('ru-Ru', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
      });
      const data = {
        address: this.nameData,
        description: this.description,
        type: 'street',
        geometry2: null,
        extraIds: null
      };
      data.date = format(this.selectedData);

      this.$store.dispatch('setCleaning', { data }).then(resp => {
        if (resp?.id) {
          _this.saveItems.push(resp);
          _this.count++;
        }
        _this.nameData = null;
        _this.selectedData = null;
        _this.description = null;
      });
    },
    loadNext() {
      this.next2 = false;
      this.next1 = true;
      this.isLoadingNext = false;
    },
    loadNext2() {
      this.next2 = true;
      this.next1 = false;
      this.isLoadingNext = false;
    },
    clickToggle(item, day, index, index2) {
      const _this = this;
      const json = {
        action: 'toggle',
        cleaningId: item.ids[this.dateFilter(new Date(day))] ? item.ids[this.dateFilter(new Date(day))] : 0,
        addressId: item.addressId,
        date: this.dateFilter(new Date(day))
      };

      const color = [];
      const api = new PlaceApi();
      api.operateCleaning(json).then(resp => {
        if (!item.ids[_this.dateFilter(new Date(day))]) {
          _this.calendarList[index].ids[_this.dateFilter(new Date(day))] = resp.id;
          _this.calendarList[index].days.push(_this.dateFilter(new Date(day)));
          color[day] = _this.caledrarColor(index2, item.days, day);
          _this.colors[item.addressId] = color;
        } else {
          _this.calendarList[index].days = _this.calendarList[index].days.filter(el => el != _this.dateFilter(new Date(day)));
          color[day] = 'metropolis--bg';
          _this.colors[item.addressId] = color;
        }

        if (resp?.hidden == 1) {
          _this.calendarList[index].days = _this.calendarList[index].days.filter(el => el != _this.dateFilter(new Date(day)));
          color[day] = 'metropolis--bg';
          _this.colors[item.addressId] = color;
        }
        if (resp?.hidden == 0) {
          _this.calendarList[index].days.push(_this.dateFilter(new Date(day)));
          color[day] = _this.caledrarColor(index2, item.days, day);
          _this.colors[item.addressId] = color;
        }
        _this.countIndex++;
      });
    },
    caledrarColor(index, date, day) {
      let color = 'metropolis--bg';
      const dateDay = date;
      for (let i = 0; i < dateDay.length; i++) {
        if (this.dateFilter(new Date(dateDay[i])) == this.dateFilter(new Date(day))) {
          if (this.getCleaningStatus(dateDay[i]) == 'progress') {
            color = 'rocky--bg';
          } else if (this.getCleaningStatus(dateDay[i]) == 'wait') {
            color = 'lebowski--bg';
          } else if (this.getCleaningStatus(dateDay[i]) == 'done') {
            color = 'matrix--bg';
          }
        }
      }

      return color;
    },
    caledrarColorAdd(index, date, day) {
      let color = 'metropolis--bg';
      const dateDay = date;
      if (new Date(dateDay).getDate() == index) {
        if (this.getCleaningStatus(dateDay) == 'progress') {
          color = 'rocky--bg';
        } else if (this.getCleaningStatus(dateDay) == 'wait') {
          color = 'lebowski--bg';
        } else {
          color = 'matrix--bg';
        }
      }

      return color;
    },
    caledrarColorSave(index, date, day) {
      let color = 'metropolis--bg';
      const dateDay = date;
      for (let i = 0; i < dateDay.length; i++) {
        if (new Date(dateDay[i]).getDate() == index) {
          if (this.getCleaningStatus(dateDay[i]) == 'progress') {
            color = 'rocky--bg';
          } else if (this.getCleaningStatus(dateDay[i]) == 'wait') {
            color = 'lebowski--bg';
          } else if (this.getCleaningStatus(dateDay[i]) == 'done') {
            color = 'matrix--bg';
          }
        }
      }

      return color;
    },
    dayCount(id) {
      if (this.dayNamber > 6) {
        this.dayNamber = 0;
        id = 0;
      }
      this.dayNamber++;

      return this.days[id];
    },
    dayCountNext(id) {
      if (this.dayNamberNext > 6) {
        this.dayNamberNext = 0;
        id = 0;
      }
      this.dayNamberNext++;

      return this.days[id];
    },
    getWeekDay(date) {
      return date.getDay();
    },
    uploadContainer() {
      this.load = false;
      this.upload = true;
    },
    clickUpload(items = false) {
      if (items) {
        this.isLoadingNext = true;
      }
      this.$refs.childComponent.handleFiles(items, this.file);
    },
    uploadFileTrue() {
      this.next1 = true;
      this.next2 = false;
      this.upload = false;
      this.isLoadingNext = false;
    },
    uploadFile(dates, file) {
      const _this = this;
      this.file = file;
      this.willSave2 = dates.willSave2;
      this.items = dates;

      const dateNextEnd = new Date(dates.debug.daysRowSanitized[dates.debug.daysRowSanitized.length - 1][1]);
      let dateNext = new Date();
      let fromDay = new Date(dateNext);
      for (let s = 0; s < dates.debug.daysRowSanitized.length; s++) {
        if (dates.debug.daysRowSanitized[s][1] != undefined) {
          dateNext = new Date(dates.debug.daysRowSanitized[s][1]);
          const week = new Date(dates.debug.daysRowSanitized[s][1]);
          this.dayNamberNext = this.getWeekDay(week);
          fromDay = new Date(dateNext);
          s = dates.debug.daysRowSanitized.length + 1;
          this.fromDay = fromDay;
        }
      }
      _this.dateNext = [];
      for (let s = 1; s < 2;) {
        if (dateNextEnd <= dateNext) {
          s = 2;
        }
        _this.dateNext.push(dateNext.getDate());
        dateNext.setDate(dateNext.getDate() + 1);
      }
      const api = new PlaceApi();
      api.getCityroads('').then(resp => {
        _this.itemsFunc = resp.map(el => ({
          value: el.label,
          disabled: false,
          id: el.value.id
        }));
      });
      for (let i = 0; i < this.dateNext.length; i++) {
        this.dayWeekNext[i] = this.dayCountNext(this.dayNamberNext);
      }

      _this.loadNext2();
      _this.count++;
    },
    showUp() {
      this.showUpload = false;
    },
    uploadError(value) {
      this.error = true;
      this.upload = false;
      this.showUpload = true;
      this.errors = value;
      setTimeout(this.showUp, 10000);
    },
    mapStrokeColor(date, details) {
      let color = '#D06E0B';
      const toDay = new Date().toJSON().substring(0, 10); // 'yyyy-mm-dd'
      let from = 9;
      let to = 18;
      if (details?.selectedDate) {
        if (details?.selectedDate != 'one') {
          from = 0;
          to = 6;
        }
      }
      const hours = new Date().getHours();
      if (toDay === date) {
        if (hours < from) {
          color = '#D06E0B';
        } else if (hours > to) {
          color = '#57A003';
        } else {
          color = '#3D75E4';
        }
      }

      if (new Date(date) < new Date() && toDay != date) {
        color = '#57A003';
      }

      return color;
    },
    onChangeStartDate(date) {
      if (typeof date === 'string') {
        this.filterDoneStartDate = date;
        this.$store.dispatch('loadAll', {
          from: this.filterDoneStartDate ? this.filterDoneStartDate : '',
          to: this.filterDoneEndDate ? this.filterDoneEndDate : ''
        });
        this.listAll;
        this.countSearch++;
      } else {
        this.filterDoneStartDate = null;
      }
    },
    tableResize() {
      this.selectedView = 'table';
      this.countMapEvent++;
    },
    mapResize() {
      this.selectedView = 'map';
      this.countMapEvent++;
    },
    mapResizeEvent() {
      this.selectedViewEvent = 'map';
      this.countMap++;
    },
    calendarResize() {
      this.selectedView = 'calendar';
      this.countMapEvent++;
    },
    tableResizeEvent() {
      this.selectedViewEvent = 'table';
      this.countMap++;
    },
    tab(e) {
      this.activeTabId = e;
      console.log(e);
    },
    onChangeEndDate(date) {
      if (typeof date === 'string') {
        this.filterDoneEndDate = date;
        this.$store.dispatch('loadAll', {
          from: this.filterDoneStartDate ? this.filterDoneStartDate : '',
          to: this.filterDoneEndDate ? this.filterDoneEndDate : ''
        });
        this.listAll;
        this.countSearch++;
      } else {
        this.filterDoneEndDate = null;
      }
    },
    dateFilter(e) {
      const date = new Date(e);
      let dd = date.getDate();
      if (dd < 10) dd = `0${dd}`;
      let mm = date.getMonth() + 1;
      if (mm < 10) mm = `0${mm}`;
      const yy = date.getFullYear();

      return `${yy}-${mm}-${dd}`;
    },
    searchInfo() {
      if (this.selectedCategoryMer) {
        this.selectedCategoryMer = this.dateFilter(this.selectedCategoryMer);
      }
      if (this.selectedCategoryMerTo) {
        this.selectedCategoryMerTo = this.dateFilter(this.selectedCategoryMerTo);
      }
      this.countSearch++;
      this.countMer++;
    },
    deleteFunc() {
      this.onChangePeriod();
    },
    onChangePeriod(e) {
      const _this = this;
      if (this.selectedPeriod) {
        const date = new Date(this.selectedPeriod);
        let dd = date.getDate();
        if (dd < 10) dd = `0${dd}`;
        let mm = date.getMonth() + 1;
        if (mm < 10) mm = `0${mm}`;
        const yy = date.getFullYear();

        this.selectedPeriod = `${yy}-${mm}-${dd}`;
      }

      if (this.selectedPeriodFrom) {
        const date = new Date(this.selectedPeriodFrom);
        let dd = date.getDate();
        if (dd < 10) dd = `0${dd}`;
        let mm = date.getMonth() + 1;
        if (mm < 10) mm = `0${mm}`;
        const yy = date.getFullYear();
        this.selectedPeriodFrom = `${yy}-${mm}-${dd}`;
      }

      const options = {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric'

      };

      const fromDay = this.selectedPeriod ? new Date(this.selectedPeriod) : new Date();
      const toDay = this.selectedPeriodFrom ? new Date(this.selectedPeriodFrom) : new Date();

      const week = new Date(fromDay);
      this.dayNamber = this.getWeekDay(week);

      fromDay.setDate(fromDay.getDate());
      const day = fromDay.toLocaleString('ru-Ru', options);

      toDay.setDate(toDay.getDate());
      const dayTo = toDay.toLocaleString('ru-Ru', options);

      const dateNext = fromDay;
      _this.date = [];
      let i = 0;
      for (let s = 1; s < 2;) {
        if (toDay <= dateNext) {
          s = 2;
        }
        _this.date.push({ index: dateNext.getDate(), day: new Date(dateNext.setDate(dateNext.getDate())) });
        dateNext.setDate(dateNext.getDate() + 1);

        this.dayWeek[i] = this.dayCount(this.dayNamber);
        i++;
      }
      this.$store.dispatch('loadInfo', { dateFrom: day, dateTo: dayTo });
      this.$store.dispatch('getCleaning', { dateFrom: day, dateTo: dayTo });
      this.count++;
    },
    getStatus(date, dateEnd, timeTo, timeFrom) {
      const toDay = new Date().toJSON().substring(0, 10); // 'yyyy-mm-dd'
      let from = 9;
      let to = 18;
      if (timeTo && timeFrom) {
        from = timeTo.split(':')[0];
        to = timeFrom.split(':')[0];
      }
      let status = 'wait';
      const hours = new Date().getHours();
      if (toDay >= date && toDay <= dateEnd) {
        status = 'progress';
        if (toDay == date) {
          if (hours < from) {
            status = 'wait';
          }
        }

        if (toDay == dateEnd) {
          if (hours < from) {
            status = 'progress';
          }
          if (hours > to) {
            status = 'done';
          }
        }
      }

      if (new Date(dateEnd) < new Date()) {
          status = 'done';
      }

      return status;
    },
    getCleaningStatus(date, details) {
      const toDay = new Date().toJSON().substring(0, 10); // 'yyyy-mm-dd'
      let from = 9;
      let to = 18;
      if (details?.selectedDate) {
        if (details?.selectedDate != 'one') {
          from = 0;
          to = 6;
        }
      }

      const hours = new Date().getHours();
      if (toDay == date) {
        if (hours < from) {
          return 'wait';
        }
        if (hours > to) {
          return 'done';
        }
        return 'progress';
      }

      if (new Date(date) < new Date()) {
        return 'done';
      }

      return 'wait';
    },
    async onDelete() {
    },
    deletePeriod() {
      this.$refs.modal.openModal(DeleteModalPeriod, {
        periodTo: this.selectedPeriod,
        periodFrom: this.selectedPeriodFrom
      });
    },
    copyPeriod() {
      this.$refs.modal.openModal(CopyModalPeriod, {
        periodTo: this.selectedPeriod,
        periodFrom: this.selectedPeriodFrom
      });
    },
    formatDate(date) {
      return new Date(date).toLocaleString('Ru-ru', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
      });
    },
  }
};
</script>

<style lang="scss" scoped>
.edit {
  &__map {
    width: 100%;
    overflow: hidden;
    height: 380px;
  }
}

.objects {
  &__filter {
    margin-top: 24px;
    display: flex;
  }

  &__list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 4px;

    > * {
      min-width: 0;
    }

    a {
      border-radius: 16px;

      &:hover {
        box-shadow: 0 4px 16px rgb(4 21 62 / 16%);
      }
    }
  }

  &__map {
    height: 700px;
    border-radius: 24px;
    overflow: hidden;
  }
}

.margin-right {
  float: right;
  margin-top: 10px;
  margin-right: 10px;
  z-index: 1000;
  cursor: pointer;
}

.align-center {
  align-items: center;
}

.upload-color {
  color: #3d75e4;
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

.upload {
  overflow-y: scroll;
  background-color: white;
  z-index: 9999;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

.container-upload {
  width: 87%;
  height: 300px;
}

.parent-save {
  display: block;
  position: absolute;
  width: 40%;
  height: 400px;
  top: 40%;
  margin-left: 33%;
  margin-top: -50px;
}

.parent {
  display: block;
  position: absolute;
  width: 40%;
  height: 400px;
  top: 40%;
  margin-left: 33%;
  margin-top: -150px;
}

.parent_next {
  display: block;
  position: absolute;
  height: 400px;
  top: 45px;
  width: 91%;
  margin-left: 8%;
}

::v-deep .rir-modal__content-window {
  max-width: 850px;
}

.icon-close {
  cursor: pointer;
  position: absolute;
  margin-left: calc(100% - 60px);
  margin-top: -28px;
}

.icon-div {
  display: table;
  position: relative;
}

.table-body {
  margin-left: 0px !important;
  border-radius: 8px;
}

.table-body:hover {
  height: 33px;
  background: rgba(4, 21, 62,0.04);
}

.table-body:hover > div {
  border-bottom: 1px solid rgba(4, 21, 62,0.04);
}

.table {
  height: 33px;
}

.table-line {
  margin-left: 20px;
  margin-right: 20px;
  display: inline-flex;
  border-bottom: 1px solid rgba(4, 21, 62,0.04);
}

.table-line-head {
  margin-left: 20px;
  margin-right: 20px;
  display: inline-flex;
  border-bottom: 1px solid rgba(4, 21, 62,0.04);
}

.table-text {
  width: 250px;
  max-height: 33px;
  float: left;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 30px;
  color: #04153E;
  opacity: 0.72;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.legend {
  &__color {
    width: 16px;
    height: 16px;
    background: #57A003;
    opacity: 0.32;
    float: left;
    border-radius: 4px;
    display: block;
  }
}

.scroll-y {
  max-height: 600px;
  overflow: hidden;
  overflow-y: scroll;
}

.error-load {
  position: absolute;
  height: 16px;
  left: 58px;
  top: calc(50% - 16px / 2 + 6px);
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #34040F;
  opacity: 0.48;
}

.scroll-y::-webkit-scrollbar, .scroll-y::-webkit-scrollbar-thumb {
  width: 4px;
  background: #e4edfb;
  border-radius: 2px;
  opacity: .72;
}

.scroll-y::-webkit-scrollbar-track {
  width: 4px;
  background: #f6f9fe;
}

.style-text {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: #04153E;
  opacity: 0.72;
  width: 33px;
  overflow: hidden;
}

.object-text {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #04153E;
  opacity: 0.48;
}
.style-text-color {
  color: #D06E0B;
}

.text-style {
  height: 40px;
}

.text-style-span {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  opacity: 0.48;
  width: 100%;
  display: table;
}

.upload::-webkit-scrollbar, .upload::-webkit-scrollbar-thumb {
  width: 4px;
  background: #e4edfb;
  border-radius: 2px;
  opacity: .72;
}

.upload::-webkit-scrollbar-track {
  width: 4px;
  background: #fff;
}

.grid-cont {
  grid-column-gap: 24px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.time {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #04153E;
  opacity: 0.48;
}

.date_time {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #04153E;
  opacity: 0.72;

}

.date_text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #04153E;
  opacity: 0.48;
  text-align:justify;
}
</style>
<style>
 .scroll-x {
   width: 100%;
   overflow-x: scroll;
 }
 .scroll-x::-webkit-scrollbar, .scroll-x::-webkit-scrollbar-thumb {
   height: 4px;
   background: #e4edfb;
   border-radius: 2px;
   opacity: .72;
 }

 .scroll-x::-webkit-scrollbar-track {
   height: 4px;
   background: #f6f9fe;
 }
 .scr::-webkit-scrollbar-track {
   background: #fff !important;
 }

 .upload-color {
   color: #3d75e4;
   cursor: pointer;
 }

 .load-change {
   z-index: 1000;
   position: fixed;
   height: 56px;
   width: 70%;
   left: 30%;
   right: 0%;
   top: calc(100% - 56px);
   background: #FFFFFF;
   box-shadow: 0px 8px 32px rgba(39, 20, 2, 0.12);
   border-radius: 28px;
 }

 .image-load {
   margin: 12px;

   float: left;
 }

 .load-text {
   font-style: normal;
   font-weight: bold;
   font-size: 16px;
   line-height: 20px;
   color: #04153E;
 }

 .load-text-end {
   margin-top: 10px;
 }

 .load-text-error {
   margin-top: 10px;
 }

 .load-text-right {
   cursor: pointer;
   float: right;
   margin-top: -18px;
   margin-right: 24px;
 }

 .date {
   font-style: normal;
   font-weight: normal;
   font-size: 16px;
   line-height: 20px;
   color: #04153E;
   opacity: 0.48;
 }

 .align-center {
   align-items: center;
 }

 .uploads {
   background-color: white;
   z-index: 999;
   height: 100%;
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
 }

 .upload::-webkit-scrollbar-track {
   width: 4px;
   background: #FFFFFF;
 }

 .upload::-webkit-scrollbar {
   width: 4px;
   background: #e4edfb;
   border-radius: 2px;
   opacity: 0.72;
 }

 .upload::-webkit-scrollbar-thumb {
   width: 4px;
   background: #e4edfb;
   border-radius: 2px;
   opacity: 0.72;
 }

 .margin-right {
   margin-top: 12px;
   margin-right: 12px;
   z-index: 1000;
   cursor: pointer;
 }

 .clear {
   height: 56px;
   background: #3D75E4;
   opacity: 0.08;
   border-radius: 8px;
 }

 .notSave {
   width: 100%;
   display: inline-block;
 }

 .objects {
   &__filter {
     display: flex;
   }
 }

 .w-20 {
   width: 20px;
 }

 .table__display {
   display: table;
 }

 .w-100 {
   width: 100%;
 }

 .w-91 {
   width: 91%;
 }

 .xlsx {
   width: 100%;
   height: 400px;
   display: grid;
 }

 .grid {
   display: grid;
 }

 .h-320 {
   height: 320px;
 }

 .mr-9px {
   margin-right: 9px;
 }

 .r-map {
   height: 100%;
   width: 100%;
   position: sticky;
   border-radius: 24px;
   overflow: hidden;
   &__legend {
     z-index: 5000;
     padding: 8px 12px;
     background: var(--rir-amelie);
     position: absolute;
     left: 16px;
     bottom: 16px;
     border-radius: 4px;
   }
 }

 .legend {
   &__color {
     margin-top: 2px;
     margin-right: 6px;
     width: 8px;
     height: 8px;
     border-radius: 50%;
   }
 }

</style>
<style>
.RButton__icon {
  margin-right: 0px !important;
}

.RButton{
  display: flex;
  justify-content: center;
  align-items: center !important;
}

.RButton.helike {
  width: 40px;
  padding: 10px 12px !important;
}

.fargo {
  background-color: unset !important;
}
</style>
