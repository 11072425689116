<template>
  <div
    ref="content"
    :class="{'button_fly': flyButton}"
  >
    <router-link
      class="flex align-center sulguni mb-7 print"
      :to="{
        name: 'index',
      }"
    >
      <r-icon
        class="mr-2 mt-2px"
        icon="arrow-left"
        size="16"
      />
      Назад
    </router-link>
    <loading-content v-if="isLoading || loadSave"/>
    <div v-else>
      <div>
        <h1
          class="ricotta"
          style="overflow: auto;"
        >
          Сохранённые объекты
        </h1>
      </div>
      <loading-content v-if="isLoading"/>
      <div
        v-else
        v-show="information"
        :key="counts"
      >
        <r-tabs
          class="mt-7 flex-1"
          :items="tabs"
          v-model="activeTabId"
        />

        <div class="flex mt-10">
          <div
            class="flex-1 mr-6"
          >
            <div class="flex">
              <div
                class="mb-4 ml-auto parmigiano text-titanic opacity-48"
              >
                Всего {{ countSave }}
              </div>
            </div>
            <r-input
              class="flex-1 float custom w-100"
              value=""
              v-model="search"
              label="Поиск"
              :params-input="{&quot;type&quot;:&quot;input&quot;}"
              after-icon="search"
            >
              <template #before>
                <r-icon
                  icon="search"
                  fill="pianist"
                  size="16"
                />
              </template>
            </r-input>
            <div class="flex">
              <router-link
                class="flex align-center sulguni rocky--text mt-4"
                :to="{ name: 'new-objects' ,
                       params: {
                         type: activeTabId.id == 'street' ? 'street' : 'yard'
                       }
                }"
              >
                <r-icon
                  class="mr-2"
                  icon="add"
                  fill="rocky"
                  size="16"
                />
                {{ activeTabId.id == 'street' ? 'Добавить улицу' : 'Добавить двор' }}
              </router-link>
              <div class="flex ml-auto mt-4">
                <div
                  class="titanic-hover--text sulguni opacity-72 mr-2 pt-3"
                  style="font-weight: 400;"
                >
                  Сортировка
                </div>
                <drop-down-button
                  :items="periodList"
                  @select="onChangePeriod"
                />
              </div>
            </div>
            <div
              class="overflow"
              :key="'cou_' + count"
            >
              <div
                @click="link(item.id)"
                class="pointer table table-body"
                v-for="(item, index) in saveObjects"
                v-if="activeTabId.id == item.type"
              >
                <r-tooltip
                  v-if="item.type === 'street' && (!item.geometry || (item.geometry.length == 1 && item.geometry[0].length < 2))"
                  title="Не указаны контрольные точки"
                  position="top-center"
                  maxWidth="200px"
                >
                  <template #activator>
                    <div class="table table-line flex">
                      <div class="flex table-text table align-items-center">
                        {{ item.address }}
                      </div>
                      <div class="ml-auto icon-position align-items-center">
                        <div class="mr-4">
                          <span
                            v-if="item.type === 'street' && (!item.geometry || (item.geometry.length == 1 && item.geometry[0].length < 2))"
                            class="legend__color lebowski--bg"
                            style="margin-top: -2px;box-shadow: 0px 2px 8px rgba(233, 163, 93, 0.64)"
                          />
                          <span
                            v-else-if="item.geometry.length >= 2 || item.type !== 'street'"
                            class="legend__color mt-1"
                          />
                        </div>
                        <div @click.prevent.stop="deleteItem(item.id, item.address, item.type)">
                          <r-icon
                            icon="delete"
                            class="block pointer"
                            fill="fargo"
                            size="16"
                          />
                        </div>
                      </div>
                    </div>
                  </template>
                </r-tooltip>
                <div class="table table-line flex" v-else>
                  <div class="flex table-text table align-items-center">
                    {{ item.address }}
                  </div>
                  <div class="ml-auto icon-position align-items-center">
                    <div class="mr-4">
                          <span
                            v-if="item.type === 'street' && (!item.geometry || (item.geometry.length == 1 && item.geometry[0].length < 2))"
                            class="legend__color lebowski--bg"
                            style="margin-top: -2px;box-shadow: 0px 2px 8px rgba(233, 163, 93, 0.64)"
                          />
                      <span
                        v-else-if="item.geometry.length >= 2 || item.type !== 'street'"
                        class="legend__color mt-1"
                      />
                    </div>
                    <div @click.prevent.stop="deleteItem(item.id, item.address, item.type)">
                      <r-icon
                        icon="delete"
                        class="block pointer"
                        fill="fargo"
                        size="16"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="edit__map flex-1">
            <rir-map-leaflet
              :center="coordinates || $cityCenter"
              :key="activeTabId.id"
              :legend="false"
              collapse-btn
            >
              <div
                v-for="cleaning in saveObjects"
                :key="`${cleaning.id}`"
                v-if="activeTabId.id == cleaning.type"
              >
                <v-marker-cluster
                  :options="{showCoverageOnHover: false}"
                  v-if="cleaning.type === 'yard'"
                >
                  <l-marker
                    :lat-lng="cleaning.geometry.length == 2 ? cleaning.geometry : [cleaning.lat, cleaning.lng]"
                  >
                    <l-icon
                      :icon-url="$markerIconTwo.imageHref"
                    />
                    <l-popup
                      :options="{offset: [111, -15]}"
                    >
                      <balloon-card-save
                        :id="cleaning.id"
                        :title="cleaning.address"
                        :date="cleaning.createdF"
                        :address="cleaning.description"
                      />
                    </l-popup>
                  </l-marker>
                </v-marker-cluster>
                <l-polyline
                  v-else-if="cleaning.geometry && cleaning.type === 'street'"
                  :lat-lngs="cleaning.geometry"
                  :color="'#4480F3'"
                >
                  <l-popup
                    :options="{offset: [110, 15]}"
                  >
                    <balloon-card-save
                      :id="cleaning.id"
                      :title="cleaning.address"
                      :date="cleaning.createdF"
                      :address="cleaning.description"
                    />
                  </l-popup>
                </l-polyline>
              </div>
            </rir-map-leaflet>
          </div>
        </div>
      </div>
    </div>
    <r-modal
      ref="modal"
      close-icon
    />
  </div>
</template>

<script>
import Vue from 'vue';
import vClickOutside from 'v-click-outside';
import DropDownButton from '@/components/DropDownButton.vue';
import ObjectModal from '@/components/ObjectModal.vue';
import BalloonCardSave from '@/components/BalloonCardSave';
import { LMarker, LPolyline, LIcon, LPopup } from 'vue2-leaflet';
import RirMapLeaflet from '@/components/RirMapLeaflet.vue';

Vue.use(vClickOutside);

export default {
  components: {
    LMarker,
    LPolyline,
    LIcon,
    LPopup,
    RirMapLeaflet,
    DropDownButton,
    BalloonCardSave
  },
  props: {},
  data() {
    return {
      countSave: 0,
      count: 0,
      selectedPeriodId: 'new',
      selectedPeriod: 'новые',
      periodList: [
        {id: 'sort', active: false, value: 'Сначала на "А"'},
        {id: 'reverse', active: false, value: 'Сначала на "Я"'},
        {id: 'new', active: true, value: 'Новые'},
        {id: 'old', active: false, value: 'Старые'}
      ],
      tabs: [
        {
          id: 'street',
          title: 'Улицы',
          optionsBulb: {
            color: 'rocky',
            isShape: false,
            title: '',
            position: 'eluno'
          }
        },
        {
          id: 'yard',
          title: 'Дворы',
          optionsBulb: {
            color: 'rocky',
            isShape: false,
            title: '',
            position: 'eluno'
          }
        }
      ],
      activeTabId: {
        id: 'street',
        title: 'Улицы',
        optionsBulb: {
          color: 'rocky',
          isShape: false,
          title: '',
          position: 'eluno'
        }
      },
      file: [],
      isActive: Boolean,
      time: false,
      timeTo: '0:00',
      timeFrom: '0:00',
      itemsDef: {
        address: '',
        time: '',
        date: '',
        equipment: '',
        specialVehicle: '',
        equipmentReply: '',
        equipmentStatus: 'partiallyApproved',
        equipmentFiles: '',
        pecialVehicleReply: '',
        specialVehicleStatus: 'partiallyApproved',
        specialVehicleFiles: ''
      },
      hideImg: false,
      counts: 0,
      items: [],
      isLoad: false,
      address: null,
      coordinates: null,
      isButton: true,
      isCovid: Boolean,
      value: '',
      coors: false,
      loadSave: false,
      information: true,
      observation: false,
      flyButton: false,
      searchText: null,
      timeout: null
    };
  },
  computed: {
    search: {
      get() {
        return this.searchText;
      },
      set(val) {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.searchText = val;
        }, 420);
      }
    },
    saveObjects() {
      let list = this.$store?.state?.saveInfo;
      if (this.searchText) {
        list = list.filter(el => ((el?.address.toLowerCase().indexOf(this.searchText.toLowerCase()) >= 0)));
      }

      if (list && this.selectedPeriodId == 'sort') {
        list = _.sortBy(list, 'address');
      }
      if (list && this.selectedPeriodId == 'reverse') {
        list = _.sortBy(list, 'address').reverse();
      }
      if (list && this.selectedPeriodId == 'old') {
        list = _.sortBy(list, 'created');
      }
      if (list && this.selectedPeriodId == 'new') {
        list = _.sortBy(list, 'created').reverse();
      }
      this.countSave = [];
      if (list) {
        this.countSave = list.filter(el => (el.type == this.activeTabId.id));
      }
      this.countSave = this.countSave.length;
      return list;
    },
    isLoading() {
      return this.$store.state.isObjectLoadingId;
    }
  },
  watch: {},
  mounted() {
    this.$store.dispatch('loadInfoSave');

    this.isLoad = false;
  },
  methods: {
    async deleteItem(id, title, type) {
      await this.$refs.modal.openModal(ObjectModal, {
        id,
        title,
        type
      });
    },
    onChangePeriod(e) {
      this.periodList = this.periodList.map(el => ({...el, active: el.value === e.value}));
      this.selectedPeriod = e.value;
      this.selectedPeriodId = e.id;
      this.count++;
    },
    onClickOutside(event) {
      this.time = false;
    },
    hourAdd(item) {
      for (let i = 1; i <= 24; i++) {
        const hour = document.querySelectorAll(`div.hour${i}`)[0];
        if (item == i) {
          hour.style.background = '#3D75E4';
          hour.style.color = '#FFFFFF';
          hour.style.borderRadius = '5px';
        } else {
          hour.style.background = '';
          hour.style.color = '';
        }
      }
    },
    minutesAdd(item) {
      for (let i = 1; i <= 24; i++) {
        const minutes = document.querySelectorAll(`div.minutes${i}`)[0];
        if (item == i) {
          minutes.style.background = '#3D75E4';
          minutes.style.color = '#FFFFFF';
          minutes.style.borderRadius = '5px';
        } else {
          minutes.style.background = '';
          minutes.style.color = '';
        }
      }
    },
    onDragMarker(e) {
      this.coordinates = e.get('target').geometry.getCoordinates();
      axios.get(`https://geocode-maps.yandex.ru/1.x/?apikey=f8217e42-9b86-4033-8411-a7bf4f8d6835&geocode=${this.coordinates[1]},${this.coordinates[0]}&format=json&lang=ru_RU&kind=house&ACAO=1`)
        .then(result => {
          const geo = result.data.response.GeoObjectCollection.featureMember?.[0];

          if (geo) {
            this.itemsDef.address = geo.GeoObject.name;
          }
        });
    },
    onCoordinates(item) {
      axios.get(`https://geocode-maps.yandex.ru/1.x/?apikey=f8217e42-9b86-4033-8411-a7bf4f8d6835&geocode=Нижегородская область ${this.$cityName} ${item.trim()}&format=json&lang=ru_RU&kind=house&ACAO=1`)
        .then(result => {
          const geo = result.data.response.GeoObjectCollection.featureMember?.[0]?.GeoObject;
          if (geo) {
            this.coordinates = geo.Point?.pos?.split(' ').reverse();
            console.log(this.itemsDef.address);
          }
        });
    },
    deletePhoto() {
      this.hideImg = false;
      this.items.photo0 = '';
    },
    onChangeFiles(files) {
      console.log('12', files);
      if (!files?.length) {
        this.files = [];
        return;
      }

      const formattedFiles = [];

      files.forEach(el => {
        if (el?.files?.length) {
          const fileList = el.files.map(el => ({
            contentLength: Number(el.size),
            contentType: el.type,
            filename: el.name,
            url: `${el.url}`
          }));
          formattedFiles.push(...fileList);
        } else {
          formattedFiles.push(el);
        }
      });
      this.hideImg = true;
      this.files = formattedFiles;
      console.log('12', formattedFiles[0].url);
      this.items.photo0 = formattedFiles[0].url;
    },
    observationFunc() {
      this.information = false;
      this.observation = true;
    },
    inputCovid() {
      if (this.value != '') {
        this.value = this.value.replace(/[^\d,]/g, '');
        this.isButton = false;
      }
    },
    valueCovid() {
      let itemsProcessed = 0;

      this.$store.state.loadId.covids.forEach((item, index, array) => {
        itemsProcessed++;
        this.value += item.porch;

        if (itemsProcessed !== array.length) {
          this.value += ',';
        }
        this.isCovid = false;
      });
    },
    async loadId(result) {
      this.address = result;
    },
    active() {
      const item = {
        active: !this.isActive,
        id: this.$store.state.loadId.id
      };
      this.$store.dispatch('loadActive', item).then(result => {
        if (typeof (result.updated) !== 'undefined') {
          this.isActive = !this.isActive;
        }
      });
    },
    funcCheck() {
      this.isCovid = !this.isCovid;
      this.isButton = false;
    },
    save() {
    },
    link(id) {
      const r = this.$router.resolve({
        name: 'edit-objects',
        params: {id}
      });
      window.location.assign(r.href);
    }
  }
};
</script>

<style lang="scss" scoped>
.obj-card {
  &__title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.edit {
  &__map {
    display: block;
    height: 400px;
  }
}

.print {
  fill: #3D75E4;
  color: #3D75E4;
}

.icon-position {
  display: flex;
  width: 40px;
}

.pointer {
  cursor: pointer;
}

.table-body {
  margin-left: 0px !important;
  border-radius: 8px;
}

.table-body:hover {
  margin-top: -1px;
  padding-top: 1px;
  min-height: 57px;
  background: rgba(4, 21, 62, 0.04);
}

.table {
  min-height: 56px;
}

.table-line {
  margin-left: 20px;
  margin-right: 20px;
  border-bottom: 1px solid rgba(4, 21, 62, 0.04);
}

.table-text {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding-right: 50px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.overflow {
  height: 400px;
  overflow-y: scroll;
}

.overflow::-webkit-scrollbar-track {
  border-radius: 4px;
  background-color: #f6f9fe;
}

.overflow::-webkit-scrollbar {
  width: 4px;
  background-color: #f6f9fe;
}

.overflow::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(61, 117, 228, .08);
}

.legend {
  &__color {
    display: block;
    margin-right: 6px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
  }
}

.w-100 {
  width: 100%;
}
</style>
<style>
.RButtonAction {
  background-color: transparent !important;
}
</style>
<script setup>
</script>
