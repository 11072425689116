import ApiService from './ApiService';

export default class PlaceApi extends ApiService {
  async getTypes(payload) {
    const { data } = await this._axios.post('ajax.php?action=getPlacesTypes', {
      params: payload
    });
    return data;
  }

  async operateCleaning(json) {
    const { data } = await this._axios.post('/ajax.php?action=operateCleaning', json);
    return data;
  }

  async getList(payload) {
    const noCache = Math.floor(Math.random(1) * 1000);
    const { data } = await this._axios.post('ajax.php?action=getPlaces&wHidden=1', payload);
    return data;
  }

  async getCityroads(payload) {
    const { data } = await this._axios.post(`/ajax.php?action=getCityroads&term=${payload}`, {
      all: 1,
      limit: -1
    });
    return data;
  }

  async operateAddresses(payload) {
    const { data } = await this._axios.post('/ajax.php?action=operateAddresses', payload);
    return data;
  }

  async getTransport(payload) {
    const { lat, lng } = payload;
    const { data } = await this._axios.get(
      `transportProxy.php?url=stations&lat=${lat}&lng=${lng}&radius=500`
    );
    return data;
  }

  async getActive() {
    const { data } = await this._axios.put('/ajax.php?action=getCleaningClaims');

    return data;
  }

  async setItem(payload) {
    const { data } = await this._axios.post('/ajax.php?action=addNewPlace', payload);
    return data;
  }

  async setRating(payload) {
    const { data } = await this._axios.post('/ajax.php?action=operatePublicRating', payload);
    return data;
  }

  async getActivity(payload) {
    const { data } = await this._axios.put('/ajax.php?action=getCleaningClaims', payload);

    return data;
  }

  async setSave(item) {
    const { data } = await this._axios.post('/ajax.php?action=operateMinimapEvent', {
      action: 'updateJson',
      currentMainMenuNumber: 76,
      item
    });
    return data;
  }

  async setSaveApprove(item) {
    const { data } = await this._axios.post('/ajax.php?action=operateMinimapEvent', {
      action: 'approve',
      currentMainMenuNumber: 76,
      approved: Number(item.approved),
      id: item.id
    });
    return data;
  }

  async setSaveNew(item) {
    const { data } = await this._axios.post('/ajax.php?action=operateMinimapEvent', {
      action: 'updateJson',
      currentMainMenuNumber: 76,
      item
    });
  }

  async setDelete(item) {
    const { data } = await this._axios.post('/ajax.php?action=operateMinimapEvent', {
      action: 'delete',
      currentMainMenuNumber: 76,
      id: item.id
    });
    return data;
  }

  async getComments(item) {
    const { data } = await this._axios.get(`/ajax.php?action=getGarbageClaimComments&id=${item.id}`);
    return data;
  }

  async getCommentSave(item) {
    const { data } = await this._axios.post('/ajax.php?action=sendToGarbageClaimComments', {
      id: item.claimId,
      comment: item.comment,
      commentId: item.id
    });
    return data;
  }

  async getDeleteComment(item) {
    const { data } = await this._axios.post('/ajax.php?action=deleteGarbageComment', {
      id: item.claimId,
      commentId: item.id
    });
  }
  //   POST /ajax.php?action=cleaningSaveDate
  // JSON:
  // {
  // address: "ул. Силкина 19"   - любая строка, если её еще нет в базе - геокодер сам найдет, или выдаст ошибку
  // addressId: Int - из базы (см.ниже) чтобы геокодер не искал
  // coordinates: [54.920475, 43.351061] - из базы (см.ниже) чтобы геокодер не искал
  // date: "07.11.2021"
  // geometry: null  - из базы (см.ниже) чтобы геокодер не искал
  // type: String (дом, улица и тп) - из базы (см.ниже) чтобы геокодер не искал
  // }

  // в случае успеха вернет что записал, включая айди адреса и тип уборки

  async getCleaning(payload) {
    const { data } = await this._axios.post('/ajax.php?action=getCleaning', {
      ...payload
    });
    return data;
  }

  async setCleaning(payload) {
    const { data } = await this._axios.post('/ajax.php?action=cleaningSaveDate', payload);
    return data;
  }

  //   6.изменить строку уборки
  // POST /ajax.php?action=operateRegion
  // JSON {
  // id:Int|-1
  // action:delete|update
  // item:{day: YYYY-MM-DD,type:String,description:String
  // }
  async changeClean({ id, ...item }) {
    const { data } = await this._axios.post('/ajax.php?action=operateRegion', {
      currentMainMenuNumber: 16,
      id,
      action: 'update',
      item
    });

    return data;
  }

  async deleteClean(id) {
    const { data } = await this._axios.post('/ajax.php?action=operateRegion', {
      currentMainMenuNumber: 16,
      id,
      action: 'delete'
    });

    return data;
  }

  async deleteDate(dateFrom, dateTo) {
    const { data } = await this._axios.post('/ajax.php?action=operateCleaning', {
      dateFrom: dateTo,
      dateTo: dateFrom,
      action: 'delete'
    });

    return data;
  }

  async copyDate(JSON) {
    const { data } = await this._axios.post('/ajax.php?action=cleaningDuplicate', JSON);

    return data;
  }
  async getAll(item) {
    const { data } = await this._axios.post('/ajax.php?action=getAll&v=2&wPhotos=1', {
      filterTypes: 1019,
      dateFrom: item.from,
      dateTo: item.to
    });

    return data;
  }

  async getInfo(payload) {
    console.log(payload);
    const { data } = await this._axios.post('/ajax.php?action=getCleaning', {
      ...payload
    });

    return data;
  }

  async getSave(payload) {
    console.log(payload);
    const { data } = await this._axios.post('/ajax.php?action=getCityroads', {
      all: 1,
      limit: -1
    });

    return data;
  }

  async setSaveAdd(JSON) {
    const { data } = await this._axios.post('/ajax.php?action=operateRegion', JSON);

    return data;
  }

  async getGeoSearch(params) {
    const { data } = await this._axios.post('/mob_ajax.php?action=geosearch', params);/* &address=ростовская&ACAO=1 */

    return data;
  }
}
